import { AgGridColumnProps } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { BDataGrid } from "../../../../Components/common/B2BDataGrid";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import format from "date-fns/format";
import "./index.css";

interface exchangeListPageProps {
  className?: string;
}
interface exchangeLstProps {
  buyRate?: string;
  currfrom?: string;
  currto?: string;
  effectiveDate?: string;
  entryBy?: string;
  id?: number;
  remark?: string;
  sellRate?: string;
  source?: string;
  updateDate?: string;
}

const columnDef: AgGridColumnProps[] = [
  {
    headerName: "Currency From",
    field: "currfrom",
    cellClass: "text-sm text-secondary",
    width: 150
  },
  {
    headerName: "Currency To",
    field: "currto",
    cellClass: "text-sm text-secondary",
    width: 150
  },
  {
    headerName: "Buy Rate",
    field: "buyRate",
    cellClass: "text-sm text-secondary text-right",
    width: 100
  },
  {
    headerName: "Sell Rate",
    field: "sellRate",
    cellClass: "text-sm text-secondary text-right",
    width: 100
  },
  {
    headerName: "Effective Date",
    field: "effectiveDate",
    cellClass: "text-sm text-secondary",
    minWidth: 100,
    valueFormatter: value => format(new Date(value.value), "yyyy-MM-dd")
  },
  {
    headerName: "Updated At",
    field: "updateDate",
    cellClass: "text-sm text-secondary",
    minWidth: 100,
    valueFormatter: value => format(new Date(value.value), "yyyy-MM-dd")
  },
  {
    headerName: "Source",
    field: "source",
    cellClass: "text-sm text-secondary"
  },
  {
    headerName: "Remark",
    field: "remark",
    cellClass: "text-sm text-secondary"
  },
  {
    headerName: "Entry By",
    field: "entryBy",
    cellClass: "text-sm text-secondary"
  }
];

export const ExchangeListPage: React.FC<exchangeListPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [exchangeLst, setExchangeLst] = useState<exchangeLstProps[]>([]);
  const { addToast } = useToasts();
  useEffect(
    () => {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/admin-exchange-list/`,
          method: "GET"
        })
          .then(res => {
            if (res.data && res.data.Status.Code === "01" && res.data.Data) {
              addToast(`Success`, { appearance: "success", autoDismiss: true });
              const response: exchangeLstProps[] = res.data.Data;
              setExchangeLst(response);
            } else if (res.data && res.data.Status.Code === "500") {
              addToast(`${JSON.stringify(res.data.Error)}`, {
                appearance: "warning",
                autoDismiss: true
              });
            }
          })
          .catch(err => {
            addToast(`System error-${JSON.stringify(err)}`, {
              appearance: "error",
              autoDismiss: true
            });
          });
      }
    },
    [isAuth, addToast]
  );
  return (
    <div className={`${className}`}>
      <BDataGrid
        columnDefs={columnDef}
        rowData={exchangeLst}
        className="w-full entry_lsit"
        pagination={true}
        paginationAutoPageSize={false}
        paginationPageSize={10}
      />
    </div>
  );
};
