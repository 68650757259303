import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { RootState } from '../../Store'
import DashbaordImg from '../../assets/dashboard.png'
import BusServicesImg from '../../assets/bus.png'
import FlightServiceImg from '../../assets/flight.png'
import MobileServiceImg from '../../assets/topup.png'
import SearchImg from '../../assets/search.png'
import MenuImg from '../../assets/menu.png'
import PowerOff from '../../assets/poweroff.png'
import FnFAdmin from '../../assets/fna.png'
import BusinessAdmin from '../../assets/business.png'
import HotelAdmin from '../../assets/hotel.png'
import AttractionAdmin from '../../assets/tour.png'
import CarrentAdmin from '../../assets/carrent.png'
import InsuranceAdmin from '../../assets/insurance.png'
import TourAdmin from '../../assets/tour_admin.png'
import './index.css'
import { userModuleProps } from '../../Pages/Dashboard/Home'
import { removeCredentials, userDataPropps } from '../../Store/Reducers/auth'

interface dashboardWrapperProps {
  className?: string
  title?: string
  userData?: userDataPropps
}

export const DashboardWrapper: React.FC<dashboardWrapperProps> = ({
  title = 'b2badmin',
  className,
  children
}) => {
  const [isMenu, setIsMenu] = useState(false)

  const { pathname } = useLocation()

  document.title = title

  const { modules } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()
  const LogoutFn = () => {
    localStorage.clear()
    dispatch(removeCredentials())
    history.push('/')
  }
  return (
    <div className={`${className} flex h-screen bg-gray-100`}>
      {/* Desktop Aside */}
      <aside className='z-20 hidden w-64 shadow-md bg-white md:block flex-shrink-0'>
        <div className='py-4 text-gray-500'>
          <Link
            className={`${pathname === '/dashboard' ? 'text-primary' : 'text-secondary'
              } ml-6 text-lg font-normal `}
            to='/dashboard'
          >
            B2B Dashbaord
          </Link>
          <ul className='mt-6'>
            <li className='relative px-6 py-2'>
              <span className='absolute inset-y-0 left-0 bg-purple-600 round-tr-lg rounded-br-lg'></span>
              <Link
                to='/dashboard'
                className={`inline-flex items-center w-full text-sm font-semibold  transition-colors duration-150 hover:text-primary ${pathname === '/dashboard' ? 'text-primary' : 'text-secondary'
                  } dark:hover:text-gray-200 dark:text-gray-100`}
              >
                <img
                  className='w-auto h-6'
                  src={DashbaordImg}
                  alt='dashbaord'
                />
                <span className='ml-4'>Dashboard</span>
              </Link>
            </li>
            {modules?.map((m: userModuleProps, i: number) => (
              <li key={i} className='relative px-6 py-2'>
                <span className='absolute inset-y-0 left-0 bg-purple-600 round-tr-lg rounded-br-lg'></span>
                <Link
                  to={`/dashboard/${m.moduleURL}`}
                  className={`inline-flex items-center w-full text-sm font-semibold  transition-colors duration-150 hover:text-primary ${pathname.includes(`/dashboard/${m.moduleURL}`)
                      ? 'text-primary'
                      : 'text-secondary'
                    } dark:hover:text-gray-200 dark:text-gray-100`}
                >
                  <img
                    className='w-auto h-6'
                    src={
                      m.moduleURL === 'bus-admin'
                        ? BusServicesImg
                        : m.moduleURL === 'flight-admin'
                          ? FlightServiceImg
                          : m.moduleURL === 'mobile-admin'
                            ? MobileServiceImg
                            : m.moduleURL === 'biz-admin'
                              ? BusinessAdmin
                              : m.moduleURL === 'acct-admin'
                                ? FnFAdmin
                                : m.moduleURL === 'carrental-admin'
                                  ? CarrentAdmin
                                  : m.moduleURL === 'hotel-admin'
                                    ? HotelAdmin
                                    : m.moduleURL === 'insurance-admin'
                                      ? InsuranceAdmin
                                      : m.moduleURL === 'attractions-admin'
                                        ? AttractionAdmin
                                        : m.moduleURL === 'tour-admin'
                                          ? TourAdmin
                                          : ''
                    }
                    alt='dashbaord'
                  />
                  <span className='ml-4'>{m.moduleName}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </aside>
      {/* Desktop Aside */}
      {/* Mobile Aside */}
      {/* backdrop */}
      {isMenu && (
        <div className='fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center transition ease-in-out duration-150'></div>
      )}
      {/* backdrop */}
      {isMenu && (
        <aside
          onClick={() => {
            setIsMenu(!isMenu)
          }}
          className='fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden transition ease-in-out duration-150'
        >
          <div className='py-4 text-gray-500'>
            <Link
              to='/dashboard'
              className='ml-6 text-lg font-bold text-gray-800 dark:text-gray-200'
            >
              B2B Admin Dashbaoard
            </Link>
            <ul className='mt-6'>
              <li className='relative px-6 py-3'>
                <span className='absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg'></span>
                <Link
                  to='/dashboard'
                  className='inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100'
                >
                  <img
                    className='w-auto h-6'
                    src={DashbaordImg}
                    alt='dashboard'
                  />
                  <span className='ml-4'>Dashboard</span>
                </Link>
              </li>
            </ul>
            <ul className=''>
              {modules?.map((m: userModuleProps, i: number) => (
                <li key={i} className='relative px-6 py-3'>
                  <Link
                    to={`/dashboard/${m.moduleURL}`}
                    className='nline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200'
                  >
                    <img
                      className='w-auto h-6'
                      src={
                        m.moduleURL === 'bus-admin'
                          ? BusServicesImg
                          : m.moduleURL === 'flight-admin'
                            ? FlightServiceImg
                            : m.moduleURL === 'mobile-admin'
                              ? MobileServiceImg
                              : m.moduleURL === 'biz-admin'
                                ? BusinessAdmin
                                : m.moduleURL === 'acct-admin'
                                  ? FnFAdmin
                                  : m.moduleURL === 'carrental-admin'
                                    ? CarrentAdmin
                                    : m.moduleURL === 'hotel-admin'
                                      ? HotelAdmin
                                      : m.moduleURL === 'insurance-admin'
                                        ? InsuranceAdmin
                                        : m.moduleURL === 'attractions-admin'
                                          ? AttractionAdmin
                                          : m.moduleURL === 'tour-admin'
                                            ? TourAdmin
                                            : ''
                      }
                      alt='dashbaord'
                    />
                    <span className='ml-4'>{m.moduleName}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      )}

      {/* Mobile Aside */}
      {/* Header */}
      <div className='flex flex-col overflow-y-auto w-full'>
        <header className='z-10 py-4 bg-white shadow'>
          <div className='container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300'>
            {/* mobile hamburger */}
            <button
              onClick={() => {
                setIsMenu(!isMenu)
              }}
              className='p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple'
            >
              <img src={MenuImg} alt='menu' className='w-auto h-8' />
            </button>
            {/* mobile bamburger */}
            {/* search input */}
            <div className='flex justify-center flex-1 lg:mr-32'>
              <div className='relative w-full max-w-xl mr-6 focus-within:text-purple-500'>
                <div className='absolute inset-y-0 flex items-center pl-2'>
                  <img src={SearchImg} alt='search' className='w-4 h-4' />
                </div>
                <input
                  className='w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input'
                  type='text'
                  placeholder='Search for projects'
                  aria-label='Search'
                />
              </div>
            </div>
            <ul className='flex items-center flex-shrink-0 space-x-6'>
              <li className='relative'>
                <button
                  onClick={() => {
                    LogoutFn()
                  }}
                  className='align-middle rounded-full focus:shadow-outline-purple focus:outline-none'
                >
                  <img src={PowerOff} alt='off' className='w-4' />
                </button>
              </li>
            </ul>
          </div>
        </header>
        <main className='h-full'>
          <div className=' mx-auto'>{children}</div>
        </main>
      </div>
      {/* Header */}
      {/* {loading && <Bloader />} */}
    </div>
  )
}
