import React from 'react'
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from '../../../Templates/DashbaordInnerWrapper'
import { useRouteMatch, Route, Switch } from 'react-router-dom'
import { HotelBookingPage } from './HotelBookings'
import { HotelSearchPage } from './HotelSearch'
export const HotelAdminPage: React.FC = () => {
  const { path } = useRouteMatch()
  const menus: tabMenuProps[] = [
    {
      name: 'Hotel Bookings',
      path: `${path}`
    },
    {
      name: 'Hotel Search',
      path: `${path}/hotel-search`
    }
  ]
  return (
    <DashbaordInnerWrapper tabMenus={menus}>
      <Switch>
        <Route exact path={`${path}`} component={HotelBookingPage} />
        <Route
          exact
          path={`${path}/hotel-search`}
          component={HotelSearchPage}
        />
      </Switch>
    </DashbaordInnerWrapper>
  )
}
