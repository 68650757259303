import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../../Components/common/B2BButton";

import { BInput } from "../../../../Components/common/B2BInput";
import { BPopup } from "../../../../Components/common/B2BPopup";
import { BRadio } from "../../../../Components/common/B2BRadio";
import { BFileUploader } from "../../../../Components/common/BDocUploader";
import { BSelect } from "../../../../Components/common/BSelect";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../Components/common/SelectTable";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import "./index.css";

interface offlineFlightBookingsPageProps {
  className?: string;
}

export interface offlineFlightBookingProps {
  agentPrice: string;
  airline: string;
  bookDate: string;
  bookingStatus: "TICKETED" | "PENDING";
  businessID: string;
  currency: string;
  flightTicket: string;
  id: number;
  issueFee: string;
  pax_name: string;
  pax_no: string;
  paymentStatus: "TICKETED" | "PENDING";
  pnr: string;
  remark: string;
  totalPrice: string;
  userID: string;
}

interface offlineFormPorps {
  bookingid: string;
  action: string;
  airline: string;
  pnr: string;
  pax_name: string;
  pax_no: string;
  total_price: number;
  agent_price: number;
  issue_fee: number;
  currency: string;
  booking_status: "TICKETED" | "PENDING" | "PAID";
  admin_remark: string;
  flight_ticket: string;
}

interface searchBookingFormProps {
  status: "pending" | "all" | "ticketed";
}
export const OfflineFlightBookingPage: React.FC<
  offlineFlightBookingsPageProps
> = ({ className }) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [bookings, setBookings] = useState<offlineFlightBookingProps[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const { addToast } = useToasts();
  const [bookingForm, setBookingForm] = useState<offlineFormPorps>({
    bookingid: "",
    action: "update",
    airline: "",
    pnr: "",
    pax_name: "",
    pax_no: "",
    total_price: 0,
    agent_price: 0,
    issue_fee: 0,
    currency: "",
    booking_status: "PENDING",
    admin_remark: "",
    flight_ticket: ""
  });
  const [searchBookingForm, setSearchBookingForm] = useState<
    searchBookingFormProps
  >({
    status: "pending"
  });
  const DoubleClickRow = (data: offlineFlightBookingProps) => {
    setShow(!show);
    setBookingForm({
      bookingid: data.id.toString(),
      action: "update",
      airline: data.airline,
      pnr: data.pnr,
      pax_name: data.pax_name,
      pax_no: data.pax_no,
      total_price: parseInt(data.totalPrice),
      agent_price: parseInt(data.agentPrice),
      issue_fee: parseInt(data.issueFee),
      currency: data.currency,
      booking_status: data.bookingStatus,
      admin_remark: "",
      flight_ticket: ""
    });
  };
  const fetchData = useCallback(
    () => {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath
            .filghtServices}/getofflinebooking/${searchBookingForm.status}`,
          method: "GET"
        })
          .then(res => {
            if (res.status === 200) {
              if (
                res.data &&
                res.data.Data &&
                res.data.Status &&
                res.data.Status.Code === "01"
              ) {
                const response: offlineFlightBookingProps[] = res.data.Data;
                setBookings(response);
              }
            }
          })
          .catch(err => {
            console.log(err, ["offline_booking_fetch_error"]);
          });
      }
    },
    [isAuth, searchBookingForm]
  );
  useEffect(
    () => {
      fetchData();
    },
    [fetchData]
  );

  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let formData: any = {};
      formData["bookingid"] = bookingForm.bookingid;
      formData["action"] = bookingForm.action;
      formData["airline"] = bookingForm.airline;
      formData["pnr"] = bookingForm.pnr;
      formData["pax_name"] = bookingForm.pax_name;
      formData["pax_no"] = bookingForm.pax_no;
      formData["total_price"] = bookingForm.total_price;
      formData["agent_price"] = bookingForm.agent_price;
      formData["issue_fee"] = bookingForm.issue_fee;
      formData["currency"] = bookingForm.currency;
      formData["booking_status"] = bookingForm.booking_status;
      formData["admin_remark"] = bookingForm.admin_remark;
      if (bookingForm.flight_ticket.startsWith("data:")) {
        formData["flight_ticket"] = bookingForm.flight_ticket;
      }
      Instance({
        url: `${b2bConfig.apiPath.filghtServices}/updateofflinebooking`,
        method: "POST",
        data: formData
      })
        .then(res => {
          if (
            res.data &&
            res.data.Data &&
            res.data.Status &&
            res.data.Status.Code === "01"
          ) {
            const message: string = res.data.Data;
            addToast(message, { appearance: "success", autoDismiss: true });
          } else if (res.data && res.data.Error) {
            const message: string = res.data.Error;
            addToast(message, { appearance: "warning", autoDismiss: true });
          }
        })
        .catch(err => {
          addToast(JSON.stringify(err), {
            appearance: "warning",
            autoDismiss: true
          });
        })
        .finally(() => {
          setShow(!show);
          fetchData();
          setBookingForm({
            bookingid: "",
            action: "update",
            airline: "",
            pnr: "",
            pax_name: "",
            pax_no: "",
            total_price: 0,
            agent_price: 0,
            issue_fee: 0,
            currency: "",
            booking_status: "PENDING",
            admin_remark: "",
            flight_ticket: ""
          });
        });
    } catch (error) {
      addToast(JSON.stringify(error), {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div className={`${className}`}>
      <form className="w-full max-w-screen-sm px-2 py-2 shadow-md bg-white my-4 rounded-md">
        <BRadio
          formData={searchBookingForm}
          setFormData={setSearchBookingForm}
          name="status"
          id="status"
          inline
          radios={[
            { text: "ALL", value: "all" },
            { text: "PENDING", value: "pending" },
            { text: "TICKETED", value: "ticketed" },
            { text: "CANCEL", value: "cancel" }
          ]}
        />
      </form>
      <SelectTable className="text-gray-800 bg-white">
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Pax Name</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Airline</span>
            </TableCell>

            <TableCell isHeader={true}>
              <span>PNR</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Price</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Issue Fee</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Price(Total)</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Status</span>
            </TableCell>

            <TableCell isHeader={true}>
              <span>Remark</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Ticket</span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400">
          {bookings.map((b, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {i + 1}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.pax_name}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.airline}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.pnr}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.agentPrice}
                  {b.currency}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.issueFee}
                  {b.currency}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.totalPrice}
                  {b.currency}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.bookingStatus}
                </span>
              </TableCell>
              <TableCell className="whitespace-pre">
                <span>
                  {b.remark}
                </span>
              </TableCell>
              <TableCell>
                {b.flightTicket &&
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className=" flex flex-row space-x-2 px-2 py-1 rounded-md text-xs font-normal text-secondary appearance-none ring-0 focus:outline-none"
                    href={b.flightTicket}
                  >
                    {" "}<svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-paperclip"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>{" "}
                    download
                  </a>}
              </TableCell>
              <TableCell>
                <button
                  onClick={() => {
                    DoubleClickRow(b);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>

      <BPopup show={show} setShow={setShow} title="Update Flight Booking">
        <form onSubmit={e => SubmitForm(e)}>
          <BSelect
            name="airline"
            options={[
              {
                name: "Please select",
                value: ""
              },
              {
                name: "MNA(MMK)",
                value: "MNA-MMK"
              },
              {
                name: "MNA(USD)",
                value: "MNA-USD"
              },

              {
                name: "GMA-USD",
                value: "GMA-USD"
              },
              {
                name: "GMA-MMK",
                value: "GMA-MMK"
              },
              {
                name: "KBZ",
                value: "KBZ(USD)"
              },
              {
                name: "MYP(MMK & USD)",
                value: "MYP"
              },
              {
                name: "YH(USD)",
                value: "YH-USD"
              },
              {
                name: "YH(MMK)",
                value: "YH-MMK"
              },
              {
                name: "PG(Bangkok Airway)",
                value: "PG"
              },
              {
                name: "Other",
                value: "Other"
              }
            ]}
            id="airline"
            formData={bookingForm}
            label="Choose Airline"
            setFormData={setBookingForm}
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="pnr"
            id="pnr"
            type="text"
            label="PNR"
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="pax_name"
            id="pax_name"
            type="text"
            label="Pressenger Name"
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="pax_no"
            id="pax_no"
            type="number"
            label="No. of Persone"
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="total_price"
            id="total_price"
            type="number"
            label="Total Price"
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="agent_price"
            id="agent_price"
            type="number"
            label="Agent Price"
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="issue_fee"
            id="issue_fee"
            type="number"
            label="Issue Fee"
          />
          <label className="text-secondary text-sm" htmlFor="currency">
            Currency
          </label>
          <BRadio
            name="currency"
            id="currency"
            radios={[
              { text: "MMK", value: "MMK" },
              { text: "USD", value: "USD" },
              { text: "SGD", value: "SGD" }
            ]}
            formData={bookingForm}
            setFormData={setBookingForm}
            inline
          />
          <label className="text-secondary text-sm" htmlFor="currency">
            Status
          </label>

          <BRadio
            name="booking_status"
            id="booking_status"
            radios={[
              { text: "PENDING", value: "PENDING" },
              { text: "TICKETED", value: "TICKETED" },
              { text: "PAID", value: "PAID" },
              { text: "CANCEL", value: "CANCEL" }
            ]}
            formData={bookingForm}
            setFormData={setBookingForm}
            inline
          />
          <BInput
            formData={bookingForm}
            setFormData={setBookingForm}
            name="admin_remark"
            id="admin_remark"
            type="text"
            label="Remark"
          />

          <BFileUploader
            name="flight_ticket"
            id="flight_ticket"
            formData={bookingForm}
            setFormData={setBookingForm}
            label="Flight Ticket"
          />
          <div className="flex  my-2">
            <Bbutton type="submit" color="primary" title="Update" />
          </div>
        </form>
      </BPopup>
    </div>
  );
};
