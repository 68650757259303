import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../Templates/DashbaordInnerWrapper";
import { AnnouncementPage } from "../Announcement";
import { AddBusinessPage } from "./AddBusiness";
import { BizDetailPage } from "./BusinessDetail";
import { BusinessListPage } from "./BusinessList";
import { UserPage } from "./Users";
export const BizAdminPage: React.FC = () => {
  const { path } = useRouteMatch();
  const menus: tabMenuProps[] = [
    {
      name: "Business List",
      path: `${path}`
    },
    {
      name: "Add Business",
      path: `${path}/add-business`
    },
    {
      name: "Announcements",
      path: `${path}/announcements`
    },
    {
      name: "Add Announcements",
      path: `${path}/announcements/add`
    },
    {
      name: "Users",
      path: `${path}/users`
    },
    {
      name: "Add User",
      path: `${path}/users/add`
    }
  ];
  return (
    <DashbaordInnerWrapper tabMenus={menus}>
      <Switch>
        <Route exact path={`${path}`} component={BusinessListPage} />
        <Route
          exact
          path={`${path}/biz-detail/:bizId`}
          component={BizDetailPage}
        />
        <Route
          exact
          path={`${path}/add-business`}
          component={AddBusinessPage}
        />
        <Route path={`${path}/users`} component={UserPage} />
        <Route path={`${path}/announcements`} component={AnnouncementPage} />
        <Redirect to="/401" />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
