import React from "react";
import './index.css'

interface rentalListPageProps{
    className?:string
}

export const RentalListPage:React.FC<rentalListPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Rental list Page </h3>
        </div>
    )
}