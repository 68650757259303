import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../Templates/DashbaordInnerWrapper";
import { AnnouncementPage } from "../Announcement";
import { BusinessBalancePage } from "./BusinessBalance";
import { CreditPage } from "./Credit";
import { DebitPage } from "./Debit";
import { ExchangeEntryPage } from "./ExchangeEntry";
import { ExchangeListPage } from "./ExchangeList";
import { LegerPage } from "./Ledger";
import { PendingApprovalPage } from "./PendingApproval";
export const AcctAdminPage: React.FC = () => {
  const { path } = useRouteMatch();
  const menus: tabMenuProps[] = [
    {
      name: "Business Transactions",
      path: `${path}`
    },
    {
      name: "Credit",
      path: `${path}/credit`
    },
    {
      name: "Debit",
      path: `${path}/debit`
    },
    {
      name: "Exchange List",
      path: `${path}/exchange-list`
    },
    {
      name: "Leger",
      path: `${path}/leger`
    },
    {
      name: "Pending Approval",
      path: `${path}/pending-approval`
    },
    {
      name: "Exchange Entry",
      path: `${path}/exchange-entry`
    },
    {
      name: "Announcements",
      path: `${path}/announcements`
    },
    {
      name: "Add Announcements",
      path: `${path}/announcements/add`
    }
  ];
  return (
    <DashbaordInnerWrapper tabMenus={menus}>
      <Switch>
        <Route exact path={`${path}`} component={BusinessBalancePage} />
        <Route exact path={`${path}/credit`} component={CreditPage} />
        <Route exact path={`${path}/debit`} component={DebitPage} />
        <Route
          exact
          path={`${path}/exchange-list`}
          component={ExchangeListPage}
        />
        <Route exact path={`${path}/leger`} component={LegerPage} />
        <Route
          exact
          path={`${path}/pending-approval`}
          component={PendingApprovalPage}
        />
        <Route
          exact
          path={`${path}/exchange-entry`}
          component={ExchangeEntryPage}
        />
        <Route path={`${path}/announcements`} component={AnnouncementPage} />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
