import React from "react";
import "../index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface singlePickerProps {
  className?: string;
  value: Date;
  setFormData: React.Dispatch<React.SetStateAction<Date | any>>;
  title?: string;
  name: string;
  id: string;
  maxDate?: Date;
  minDate?: Date;
  formData: any;
}

export const SinglePicker: React.FC<singlePickerProps> = ({
  className,
  value,
  setFormData,
  title,
  name,
  id,
  maxDate,
  minDate,
  formData
}) => {
  return (
    <div className={`${className}`}>
      <DatePicker
        dateFormat="yyyy/MM/d"
        className="appearance-none  relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 text-sm"
        placeholderText="Select Date"
        onChange={date => {
          setFormData({ ...formData, [name]: date });
        }}
        minDate={minDate}
        maxDate={maxDate}
        selected={value}
      />
    </div>
  );
};
