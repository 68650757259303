import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { b2bConfig } from "../../../Config";
import { Instance } from "../../../Services/instance";
import { RootState } from "../../../Store";
import { DashboardWrapper } from "../../../Templates/DashboardWrapper";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { MainPage } from "../Home/Main";
import { BizAdminPage } from "../BusinessAdmin";
import { AcctAdminPage } from "../AccountAdmin";
import { ActractionAdminPage } from "../ActractionAdmin";
import { BusAdminPage } from "../BusAdmin";
import { CarRentalAdminPage } from "../CarRentalAdmin";
import { FlightAdminPage } from "../FlightAdmin";
import { HotelAdminPage } from "../HotelAdmin";
import { InsureanceAdminPage } from "../InsuranceAdmin";
import { MobileAdminPage } from "../MobileAdmin";
import { UnauthenticatedPage } from "../../401";
import { TourAdminPage } from "../TourAdmin";
import {
  bzChildValueProps,
  bzItemsProps,
  exchangeRateProps,
  setBzItems,
  setExchangeRates
} from "../../../Store/Reducers/common";
import {
  businessAccountProps,
  businessProps
} from "../BusinessAdmin/BusinessList";
import { each } from "lodash";
import { ProfilePage } from "./Profile";
export interface userModuleProps {
  moduleName: string;
  moduleURL: string;
  pk: string;
}
export interface userDataPropps {
  ID?: string;
  userEmail?: string;
  userName?: string;
  userRole?: string;
  userModule?: userModuleProps[];
}

export const DashboardHome: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const [userData, setuserData] = useState<userDataPropps>();
  const authFn = useCallback(
    () => {
      if (!isAuth && localStorage.getItem("accessToken") === null) {
        history.push("/");
      }
    },
    [history, isAuth]
  );
  const bzCommonFetch = useCallback(
    () => {
      if (localStorage.getItem("accessToken")) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/admin-bizlist`,
          method: "POST"
        }).then(res => {
          let items: bzItemsProps[] = [];
          if (res.status === 200) {
            if (res.data && res.data.Data) {
              const response: businessProps[] = res.data.Data;
              each(response, (b: businessProps) => {
                if (b.businessName && b.businessID && b.businessAccount) {
                  const accountParms: bzChildValueProps[] = [];
                  each(b.businessAccount, (a: businessAccountProps) => {
                    accountParms.push({
                      name: a.accountID,
                      value: a.accountID
                    });
                  });
                  const param: bzItemsProps = {
                    name: b.businessName,
                    value: {
                      value: b.businessID,
                      account: accountParms
                    }
                  };
                  items.push(param);
                }
              });
              dispatch(setBzItems(items));
            }
          }
        });
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/user/exchangerate/`,
          method: "GET"
        })
          .then(res => {
            if (res.status === 200) {
              if (res.data && res.data.Data && res.data.Data.rates) {
                const rateResponse: exchangeRateProps[] = res.data.Data.rates;
                dispatch(setExchangeRates(rateResponse));
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [dispatch]
  );
  useEffect(
    () => {
      if (localStorage.getItem("accessToken")) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/user/profile/`,
          method: "GET"
        })
          .then(res => {
            bzCommonFetch();
            if (res.status === 200) {
              if (res.data && res.data.Data) {
                const reponse: userDataPropps = res.data.Data;
                setuserData(reponse);
              }
            }
          })
          .catch(err => {
            console.log(err, ["user_profile_error"]);
          })
          .finally(() => {
            // console.log("final_response_data");
          });
      } else {
        authFn();
      }
    },
    [authFn, bzCommonFetch]
  );
  const { path } = useRouteMatch();
  return (
    <DashboardWrapper userData={userData} title="B2B Dashboard">
      <Switch>
        <Route path={path} exact component={MainPage} />
        <Route path={`${path}/profile`} component={ProfilePage} exact />
        <Route path={`${path}/biz-admin`} component={BizAdminPage} />
        <Route path={`${path}/acct-admin`} component={AcctAdminPage} />
        <Route
          path={`${path}/attractions-admin`}
          component={ActractionAdminPage}
        />
        <Route path={`${path}/bus-admin`} component={BusAdminPage} />
        <Route
          path={`${path}/carrental-admin`}
          component={CarRentalAdminPage}
        />
        <Route path={`${path}/flight-admin`} component={FlightAdminPage} />
        <Route path={`${path}/hotel-admin`} component={HotelAdminPage} />
        <Route
          path={`${path}/insurance-admin`}
          component={InsureanceAdminPage}
        />
        <Route path={`${path}/mobile-admin`} component={MobileAdminPage} />
        <Route path={`${path}/tour-admin`} component={TourAdminPage} />
        <Route path={`${path}/401`} component={UnauthenticatedPage} />
        <Redirect to="/401" />
      </Switch>
    </DashboardWrapper>
  );
};
