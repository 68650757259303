import React from "react";
import "./index.css";

import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../../Templates/DashbaordInnerWrapper";

export const MainPage: React.FC = () => {
  
  const menus: tabMenuProps[] = [
   
    
  ];
  return (
    <DashbaordInnerWrapper tabMenus={menus}>
         
    </DashbaordInnerWrapper>
  );
};
