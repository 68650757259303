import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../../Components/common/B2BButton";
import { BCustomInput } from "../../../../Components/common/B2BCustomInput";

import { BRadio, radioDataProps } from "../../../../Components/common/B2BRadio";
import { BFileUploader } from "../../../../Components/common/BDocUploader";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import "./index.css";

interface anouncementAddPageeProps {
  className?: string;
}
export interface annoucementFormProps {
  "annType": "AGENT" | "SUPPLIER";
  "title": string;
  "Message": string;
  "attachment": string;
}

const radioOptions: radioDataProps[] = [
  {
    text: "Agent",
    value: "AGENT"
  },
  {
    text: "Supplier",
    value: "SUPPLIER"
  }
];

export const AnnouncementAddPage: React.FC<anouncementAddPageeProps> = ({
  className
}) => {
  const [annoucementForm, setAnnoucementForm] = useState<annoucementFormProps>({
    annType: "AGENT",
    title: "",
    Message: "",
    attachment: ""
  });

  const { addToast } = useToasts();
  const SubmitAnnouncementForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-create-announcement/`,
        method: "POST",
        data: annoucementForm
      })
        .then(res => {
          if (res.data && res.data.Status.Code === "01") {
            addToast(`Success`, { appearance: "success", autoDismiss: true });
          } else if (res.data && res.data.Status.Code === "500") {
            addToast(`${JSON.stringify(res.data.Error)}`, {
              appearance: "warning",
              autoDismiss: true
            });
          }
        })
        .catch(err => {
          addToast(`${JSON.stringify(err)}`, {
            appearance: "error",
            autoDismiss: true
          });
        })
        .finally(() => {
          setAnnoucementForm({
            annType: "AGENT",
            title: "",
            Message: "",
            attachment: ""
          });
          const clearValue: any = document.getElementById("attachment");
          clearValue.value = "";
        });
    } catch (error) {
      addToast(`System error -${JSON.stringify(error)}`, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div className={`${className}`}>
      <form
        onSubmit={e => {
          SubmitAnnouncementForm(e);
        }}
        className="w-full flex flex-col max-w-screen-md px-4 py-4 shadow-md"
      >
        <div className="flex flex-col space-y-2">
          <label className=" text-sm text-secondary" htmlFor="">
            Select Type
          </label>
          <BRadio
            inline
            setFormData={setAnnoucementForm}
            formData={annoucementForm}
            radios={radioOptions}
            name="annType"
            id="annType"
          />
        </div>
        <BCustomInput
          label="Title"
          formData={annoucementForm}
          setFormData={setAnnoucementForm}
          name="title"
          id="title"
          type="text"
          required={true}
        />
        <BCustomInput
          label="Message"
          formData={annoucementForm}
          setFormData={setAnnoucementForm}
          name="Message"
          id="Message"
          type="text"
          required={true}
        />
        <BFileUploader
          formData={annoucementForm}
          setFormData={setAnnoucementForm}
          label="Attachment"
          name="attachment"
          id="attachment"
        />
        <div className="flex justify-start py-2">
          <Bbutton title="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};
