import React from "react";
import './index.css'

interface notFoundPageProps{
    className?:string
}

export const NotFoundPage:React.FC<notFoundPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Not Found Page </h3>
        </div>
    )
}