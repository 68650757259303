import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";

import "./index.css";
import { Instance } from "../../../../Services/instance";
import { b2bConfig } from "../../../../Config";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../Components/common/SelectTable";
import { useToasts } from "react-toast-notifications";
import { TrashIcon } from "@heroicons/react/solid";

interface busBookingsProps {
  className?: string;
}

interface busBookingProps {
  ID: number;
  arrvto: string;
  arrvtoID: string;
  bookingDate: string;
  businessID: string;
  depfromID: string;
  depdate: string;
  depfrom: string;
  deptime: string;
  fareSource: string;
  nationality: string;
  operatorcode: string;
  pax_ID: string;
  pax_email: string;
  pax_gender: string;
  pax_name: string;
  pax_no: string;
  pax_remark: string;
  perpaxprice: string;
  seats: string;
  status: "CONFIRM" | "PENDING" | "CANCEL";
  userID: string;
}

export interface updateBookingPorps {
  "action": "update" | "delete";
  "bookingid": number;
  "pax_name": string;
  "pax_contactno": string;
  "pax_email": string;
  "pax_gender": string;
  "pax_ID": string;
  "pax_remark": string;
  "pax_no": string;
  "operatorcode": string;
  "tripID": string;
  "depfrom": number;
  "arrvto": number;
  "depdate": string;
  "deptime": string;
  "nationality": string;
  "seats": string;
  "perpaxprice": {
    "amount": number;
    "currency": string;
  };
  "bookingStatus": string;
}

export const BusBookingPage: React.FC<busBookingsProps> = ({ className }) => {
  const [bookings, setBookings] = useState<busBookingProps[]>([]);
  const [updateFormData, setUpdateFormData] = useState<updateBookingPorps>();
  const { addToast } = useToasts();
  const [status, setstatus] = useState<
    "pending" | "cancel" | "ticketed" | "all"
  >("all");

  const getBookings = useCallback(
    () => {
      Instance({
        url: `${b2bConfig.apiPath.busServices}/busbookings?status=${status}`,
        method: "GET"
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            const response: busBookingProps[] = res.data.Data;
            setBookings(response);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    [status]
  );

  useEffect(
    () => {
      getBookings();
    },
    [getBookings]
  );

  const updateBooking = (
    data: busBookingProps,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();

    setUpdateFormData({
      action: "update",
      bookingid: data.ID,
      pax_name: data.pax_name,
      pax_contactno: "",
      pax_email: data.pax_email,
      pax_gender: data.pax_gender,
      pax_ID: data.pax_ID,
      pax_remark: data.pax_remark,
      pax_no: data.pax_no,
      operatorcode: data.operatorcode,
      tripID: data.ID.toString(),
      depfrom: parseInt(data.depfromID),
      arrvto: parseInt(data.arrvtoID),
      depdate: data.depdate,
      deptime: data.deptime,
      nationality: data.nationality,
      seats: data.seats,
      perpaxprice: {
        amount: parseInt(data.perpaxprice),
        currency: "MMK"
      },
      bookingStatus: e.target.value
    });

    Instance({
      url: `${b2bConfig.apiPath.busServices}/updatebooking`,
      method: "POST",
      data: updateFormData
    })
      .then(res => {
        if (res.data && res.data.Status && res.data.Status.Code === "01") {
          addToast(res.data.Status.Message, {
            appearance: "success",
            autoDismiss: true
          });
        }
      })
      .catch(err => {
        console.log(err);
        addToast("Something wrong", {
          appearance: "warning",
          autoDismiss: true
        });
      })
      .finally(() => {
        getBookings();
      });
  };

  return (
    <div className={`${className} w-full px-2 py-2 bg-white rounded-sm`}>
      <div className="flex w-full max-w-screen-sm uppercase bg-white my-5 border-r-0 border-l-0 border-b border-t border-gray-600 rounded-md tracking-widest text-gray-600 text-xs">
        <button
          onClick={() => {
            setstatus("all");
          }}
          className={`flex w-full justify-center border-l border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "all" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>All</span>
        </button>
        <button
          onClick={() => {
            setstatus("pending");
          }}
          className={`flex w-full rounded-none justify-center  border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "pending" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Pending</span>
        </button>
        <button
          onClick={() => {
            setstatus("cancel");
          }}
          className={`flex w-full rounded-none  justify-center  border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "cancel" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Cancel</span>
        </button>
        <button
          onClick={() => {
            setstatus("ticketed");
          }}
          className={`flex w-full  justify-center  border-r rounded-l-none border-gray-700 rounded-r-md items-center content-center px-4  py-2 space-x-2 ${status ===
            "ticketed" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Ticketed</span>
        </button>
      </div>
      <SelectTable className="text-gray-800">
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Business ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Route</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Price</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Time</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Operator Code</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> email</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Gender</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Remark</span>
            </TableCell>

            <TableCell isHeader={true}>
              <span> Booking Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Status</span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400">
          {bookings.map((t, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {" "}{i + 1}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.businessID}
                </span>
              </TableCell>

              <TableCell>
                <span>
                  {t.depfrom} - {t.arrvto}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  MMK {t.perpaxprice}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.depdate}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.deptime}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.operatorcode}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_ID}
                </span>
              </TableCell>
              <TableCell>
              <span>
                {t.pax_email}
              </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_gender}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_remark}
                </span>
              </TableCell>

              <TableCell>
                <span>
                  {format(new Date(t.bookingDate), "yyyy-MM-dd")}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  <select
                    className="bg-white appearance-none px-2 border border-primary ring-primary focus:outline-none rounded-sm py-1 "
                    name="bookingStatus"
                    id="bookingStatus"
                    placeholder="Status..."
                    defaultValue={t.status}
                    onChange={e => {
                      updateBooking(t, e);
                    }}
                  >
                    <option value="PENDING">PENDING</option>
                    <option value="CONFIRM">CONFRIM</option>
                    <option value="CANCEL">CANCEL</option>
                  </select>
                </span>
              </TableCell>
              <TableCell>
                <button className="px-2 py-1 border rounded-sm flex justify-center items-center content-center space-x-2 border-gray-500 hover:border-gray-800 text-gray-500 hover:text-gray-800">
                  <TrashIcon className="w-6 h-6" />
                </button>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>
    </div>
  );
};
