import { AgGridColumnProps } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bbutton } from '../../../../Components/common/B2BButton'
import { BDataGrid } from '../../../../Components/common/B2BDataGrid'
import { BPopup } from '../../../../Components/common/B2BPopup'
import { b2bConfig } from '../../../../Config'
import { Instance } from '../../../../Services/instance'
import { RootState } from '../../../../Store'
import format from 'date-fns/format'
import './index.css'
import { useToasts } from 'react-toast-notifications'
import FileIcon from '../../../../assets/file.png'

interface pendingApprovalPageProps {
  className?: string
}

export interface pendingApprovalsDataProps {
  id?: string
  bizID?: string
  bizName?: string
  bizAccountID?: string
  tType?: string
  ref?: string
  amount?: string
  currency?: string
  beforeBalane?: number
  afterBalance?: string
  description?: string
  createdBy?: string
  createDate?: string
  refAttach?:string
}
export const DocumentRender = (props: any) => {
  return (
    props && props.data && props.data.refAttach ?
      <>
        <a target="_blank" rel="noreferrer" className=" flex flex-row space-x-2 px-2 py-1 rounded-md text-xs font-normal text-secondary appearance-none ring-0 focus:outline-none border border-success" href={props.data.attached}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
        </svg> attached file</a>
      </> :
      <></>
  );
};

const columnDefs: AgGridColumnProps[] = [
  {
    headerName: 'Business ID',
    field: 'bizID',
    maxWidth: 100,
    cellClass: 'text-center'
  },
  {
    headerName: 'Business Name',
    field: 'bizName',
    maxWidth: 150
  },
  {
    headerName: 'Account ID',
    field: 'bizAccountID',
    maxWidth: 150
  },
  {
    headerName: 'Transaction Type',
    field: 'tType',
    maxWidth: 150
  },
  {
    headerName: 'Ref:',
    field: 'ref',
    maxWidth: 100
  },
  {
    headerName: 'Amount',
    field: 'amount',
    maxWidth: 100,
    cellClass: 'text-right text-sm'
  },
  {
    headerName: 'Currency',
    field: 'currency',
    maxWidth: 100,
    cellClass: 'text-center'
  },
  {
    headerName: 'Before Balance',
    field: 'beforeBalance',
    maxWidth: 150,
    cellClass: 'text-right text-sm text-primary'
  },
  {
    headerName: 'After Balance',
    field: 'afterBalance',
    maxWidth: 150,
    cellClass: 'text-right text-sm text-danger'
  },
  {
    headerName: 'Description',
    field: 'description',
    wrapText: true,
    cellClass: 'text-xs text-secondary',
    maxWidth: 100
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    cellClass: 'text-xs text-primary',
    maxWidth: 100
  },
  {
    headerName: 'Create Date',
    field: 'createDate',
    cellClass: 'text-xs text-secondary',
    maxWidth: 150,
    valueFormatter: (value) => {
      return format(new Date(value.value), 'yyyy-MM-dd')
    }
  },
  {
    headerName: 'Attached',
    field: 'refAttach',
    cellRendererFramework: DocumentRender,
  },
]



export const PendingApprovalPage: React.FC<pendingApprovalPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth)
  const [selectedPendingApproval, setSelectedPendingApproval] = useState<
    pendingApprovalsDataProps
  >()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const { addToast } = useToasts()
  const [pendingApprovals, setPendingApprovals] = useState<
    pendingApprovalPageProps[]
  >([])

  useEffect(() => {
    if (isAuth) {
      InitializeApi()
    }
  }, [isAuth])
  const InitializeApi = () =>{
    Instance({
      url: `${b2bConfig.apiPath.bzEntry}/admin-acct-pending-approval`,
      method: 'GET'
    })
      .then(res => {
        if (res.status === 200) {
          if (res.data && res.data.Data && res.data.Status.Code === '01') {
            const response = res.data.Data
            setPendingApprovals(response)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  const DoubleClickFn = (data: pendingApprovalsDataProps) => {
    setSelectedPendingApproval(data)
    setShowPopup(true)
  }
  const AcctActionFn = (acction: string) => {

    if (acction === 'approve') {
      CallApiFn('approve')
    }
    else {
      CallApiFn('reject')
    }
  }

  const CallApiFn = (action: string) => {
    try {
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-acct-pending-approval-action`,
        method:'POST',
        data: {
          "tid": selectedPendingApproval?.id,
          "bizid": selectedPendingApproval?.bizID,
          "accountID": selectedPendingApproval?.bizAccountID,
          "action":action,
          "remark": selectedPendingApproval?.description
        }
      })
      .then(res=>{
        if (res.status === 200) {
          if (res.data && res.data.Status.Code === "01") {
            addToast(`Success`, { appearance: "success", autoDismiss: true });
          }
        }
      })
      .catch(err=>{
         addToast(`System error - ${JSON.stringify(err)}`, {
            appearance: "error"
          });
      })
      .finally(()=>{
        setShowPopup(!showPopup)
        InitializeApi()
      })

    } catch (error: any) {
      addToast(`${JSON.stringify(error.message)}`, { appearance: 'error', autoDismiss: true })
    }
  }
  return (
    <div className={`${className} w-full`}>
      <BDataGrid
        onRowDoubleClicked={e => DoubleClickFn(e.data)}
        pagination={true}
        paginationPageSize={10}
        paginationAutoPageSize={false}
        columnDefs={columnDefs}
        rowData={pendingApprovals}
        className='h-96 max-w-screen-2xl'
      />
      <div className='px-2'>
        <em className='text-xs text-danger font-thin'>* If you want to update status , double click on each row</em>
      </div>
      <BPopup show={showPopup} setShow={setShowPopup} title='Pending Approval'>
        <div className='w-full max-w-screen-xl min-w-max px-2 py-2'>
          <div className='grid grid-flow-row grid-cols-12 gap-3 select-none'>
            <div className='col-span-5'>

              <span className='text-sm text-secondary'>Business Name</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm  text-primary'> {selectedPendingApproval?.bizName} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business ID</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.bizID} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Account ID</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.bizAccountID} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Amount</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.amount} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Before Balance</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.beforeBalane} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>After Balance</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.afterBalance} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Currency</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.currency} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Created Date</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.createDate} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Description</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'>  {selectedPendingApproval?.description} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Ref</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.ref} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>T Type</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm text-primary'> {selectedPendingApproval?.tType} </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Attached file</span>
            </div>
             {
               selectedPendingApproval?.refAttach &&  <div className='col-span-7'>
               <div className='flex'>
               <a className=' flex flex-row justify-center items-center px-2 py-1 border text-xs text-secondary border-primary rounded-md appearance-none' href={selectedPendingApproval.refAttach} target='_blank' rel='noreferrer'>
                 <img src={FileIcon} alt="" className='w-auto h-6' />
                 attached file</a>
               </div>
             </div>
             }
            <div className='col-span-12'>
              <div className='flex justify-start space-x-2 items-center content-center'>
                <Bbutton NextFunction={() => { AcctActionFn('approve') }} title='Accept' color='success' />
                <Bbutton NextFunction={() => { AcctActionFn('reject') }} title='Reject' color='danger' />
              </div>
            </div>
          </div>
        </div>
      </BPopup>
    </div>
  )
}
