import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './index.css'

export interface tabMenuProps {
  name: string
  path: string
}

interface dashboardInnerWrapperProps {
  className?: string
  tabMenus?: tabMenuProps[]
}

export const DashbaordInnerWrapper: React.FC<dashboardInnerWrapperProps> = ({
  className,
  tabMenus,
  children,
}) => {
  const { pathname } = useLocation()

  return (
    <div className={`${className} w-full relative tracking-widest`}>
      <div className='bg-white shadow-md'>
        <nav className='flex flex-col sm:flex-row  '>
          {tabMenus?.map((tab: tabMenuProps, i: number) => (
            <Link
              to={tab.path}
              key={i}
              className={`
              py-4
              px-6
              block
              text-sm
              hover:text-primary
              focus:outline-none
              border-b-2
              font-normal ${pathname === tab.path ? 'border-primary text-primary' : 'border-white text-secondary'}`}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
      <div className='container mx-auto p-4 relative'>{children}</div>
      
    </div>
  )
}
