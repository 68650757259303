import React from 'react'
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from '../../../Templates/DashbaordInnerWrapper'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ImportBookingPage } from './ImportBooking'
import { OfflineFlightBookingPage } from './OfflineFlightBookings'
import { OnlineFlightBookingPage } from './OnlineFlightBookings'
import { OrderOfflinePage } from './OrderOffline'
export const FlightAdminPage: React.FC = () => {
  const { path } = useRouteMatch()
  const menu: tabMenuProps[] = [
    {
      name: 'Import Booking',
      path: `${path}`
    },
    {
      name: 'Offline Flight Bookings',
      path: `${path}/offline-flight-bookings`
    },
    {
      name: 'Online Flight Bookings',
      path: `${path}/online-flight-bookings`
    },
    {
      name: 'Order Offline',
      path: `${path}/order-offline`
    }
  ]
  return (
    <DashbaordInnerWrapper tabMenus={menu}>
      <Switch>
        <Route exact path={`${path}`} component={ImportBookingPage} />
        <Route
          exact
          path={`${path}/offline-flight-bookings`}
          component={OfflineFlightBookingPage}
        />
        <Route
          exact
          path={`${path}/online-flight-bookings`}
          component={OnlineFlightBookingPage}
        />
        <Route
          exact
          path={`${path}/order-offline`}
          component={OrderOfflinePage}
        />
      </Switch>
    </DashbaordInnerWrapper>
  )
}
