import React from "react";
import { useRouteMatch } from "react-router-dom";
import "./index.css";

interface editUserPageProps {
  className?: string;
}

export const EditUserPage: React.FC<editUserPageProps> = ({ className }) => {
  const routeMatch = useRouteMatch();
  const params: any = routeMatch.params;
  const userId = params.userId;
  return (
    <div className={`${className}`}>
      <h4>
        {userId}
      </h4>
    </div>
  );
};
