import React from "react";
import './index.css'

interface orderOfflinePageProps{
    className?:string
}

export const OrderOfflinePage:React.FC<orderOfflinePageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Order Offine Page </h3>
        </div>
    )
}