import React from "react";
import "./index.css";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { UserLstPage } from "./List";
import { AddUserPage } from "./Add";
import { EditUserPage } from "./Edit";
interface usersPageProps {
  className?: string;
}

export const UserPage: React.FC<usersPageProps> = ({ className }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact component={UserLstPage} />
      <Route path={`${path}/add`} exact component={AddUserPage} />
      <Route path={`${path}/edit/:userId`} exact component={EditUserPage} />
    </Switch>
  );
};
