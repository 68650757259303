import React, { useEffect, useState } from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { Instance } from "../../../../Services/instance";
import { b2bConfig } from "../../../../Config";
import { businessProps } from "../BusinessList";
import { Bbutton } from "../../../../Components/common/B2BButton";
import { EditBusiness } from "../../../../Components/dashbaord/BizAdmin/BusinessDetail/Edit";
import { ShowBusiness } from "../../../../Components/dashbaord/BizAdmin/BusinessDetail/Show";
import { businessFormDataProps } from "../AddBusiness";
import { useToasts } from "react-toast-notifications";
interface busDetailPageProps {
  className?: string;
}
export const BizDetailPage: React.FC<busDetailPageProps> = ({ className }) => {
  const param: any = useParams();
  const bizId = param.bizId;
  const [mode, setMode] = useState<"edit" | "update">("edit");
  const [businessData, setBusinessData] = useState<businessProps>();
  const { addToast } = useToasts()
  const [businessFormData, setbusinessFormData] = useState<
    businessFormDataProps
  >({
    businessID: "",
    businessName: "",
    businessCity: "",
    businessEmail: "",
    businessType: "",
    businessAddress: "",
    businessCountry: "",
    businessLogo: "",
    businessPhone: "",
    businessStatus: "ACTIVE",
    businessService: []
  });

  const SubmitUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setMode(mode === 'edit' ? 'update' : 'edit')


    if (mode === 'update') {
      try {
        let formObj: any = {}
      
          formObj['businessID'] = businessFormData.businessID
       
        if (businessData?.businessName !== businessFormData.businessName) {
          formObj['businessName'] = businessFormData.businessName
        }
        if (businessData?.businessLogo !== businessFormData.businessLogo) {
          formObj['businessLogo'] = businessFormData.businessLogo
        }
        if (businessData?.businessAddress !== businessFormData.businessAddress) {
          formObj['businessAddress'] = businessFormData.businessAddress
        }
        if (businessData?.businessCity !== businessFormData.businessCity) {
          formObj['businessCity'] = businessFormData.businessCity
        }
        if (businessData?.businessEmail !== businessFormData.businessEmail) {
          formObj['businessEmail'] = businessFormData.businessEmail
        }
        if (businessData?.businessPhone !== businessFormData.businessPhone) {
          formObj['businessPhone'] = businessFormData.businessPhone
        }
        if (businessFormData.businessService.length>0) {
          formObj['businessService'] = businessFormData.businessService
        }
        if (businessData?.businessStatus !== businessFormData.businessStatus) {
          formObj['businessStatus'] = businessFormData.businessStatus
        }
        if (businessData?.businessType !== businessFormData.businessType) {
          formObj['businessType'] = businessFormData.businessType
        }
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/update-business`,
          method: 'POST',
          data: formObj
        })
          .then(res => {
            if (res.status === 200) {
              if (
                res.data &&
                res.data.Status.Code === "01"
              ) {

                addToast(` Business updated successfully!`, { appearance: 'success' });
              }
              if (res.data && res.data.Error) {
                addToast(`${JSON.stringify(res.data.Error)}`, { appearance: 'warning' })
              }
            }
          })
          .catch(err => {
            addToast(`${JSON.stringify(err)}`, { appearance: 'warning' })
          })
      } catch (error) {
        addToast(`System error`, { appearance: "error" });
      }
    }

  }
  useEffect(
    () => {
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-business-detail/${bizId}`
      })
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.Data) {
              const reponse: businessProps = res.data.Data;
              setBusinessData(reponse);
              const resp: businessFormDataProps = res.data.Data;
              setbusinessFormData({
                businessID: resp.businessID,
                businessName: resp.businessName,
                businessService: [],
                businessStatus: resp.businessStatus,
                businessPhone: resp.businessPhone,
                businessLogo: resp.businessLogo,
                businessCountry: resp.businessCountry,
                businessAddress: resp.businessAddress,
                businessCity: resp.businessCity,
                businessType: resp.businessType,
                businessEmail: resp.businessEmail
                
              });
            }
          }
        })
        .catch(err => {
          console.log(["business_detail_fetch_error"], err);
        })
        .finally(() => {
          console.log(["business_detail_fetch_done"]);
        });
    },
    [bizId]
  );
  return (
    <div className={`${className}`}>
      <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => { SubmitUpdate(e) }}>
        <div className="flex justify-end items-center content-center py-2">
          <Bbutton
            className="text-sm font-thin"
            title={mode === "edit" ? "Edit" : "Update"}
            color="primary"
            type='submit'
          />
        </div>
        {mode === "update" &&
          <EditBusiness
            formData={businessFormData}
            setFormData={setbusinessFormData}
          />}
      </form>
      {mode === "edit" && <ShowBusiness data={businessData} />}
      {/* Transactions */}
      {/* {businessData &&
        businessData.businessBalance &&
        mode === "edit" &&
        <BzTransactions data={businessData.businessBalance} />} */}
    </div>
  );
};
