import React from 'react'
import './index.css'

interface anouncementUpdatePageeProps{
    className?:string
}

export const AnnouncementUpdatePage:React.FC<anouncementUpdatePageeProps> = ({className})=>{
    return(
        <div className={`${className}`}>
                <h4>anouncementUpdatePagee</h4>
        </div>
    )
}