const live = process.env.NODE_ENV === 'development';

export const b2bConfig = {
  apiPath: {
    bzEntry:
      live
        ? 'https://b2bapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://b2bapi.gemtechmyanmar.com/prod/api/v1',
    bzEntry2:
      live
        ? 'https://4xj2wy1zr0.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1'
        : 'https://4xj2wy1zr0.execute-api.ap-southeast-1.amazonaws.com/prod/api/v1',
    bzEntry3:
      live
        ? 'https://twky5d4s49.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1'
        : 'https://twky5d4s49.execute-api.ap-southeast-1.amazonaws.com/prod/api/v1',
    dgServices:
      live
        ? 'https://ds.api.gemtechmyanmar.com/dev/api/v1'
        : 'https://ds.api.gemtechmyanmar.com/prod/api/v1',
    busServices:
      live
        ? 'https://busapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://busapi.gemtechmyanmar.com/prod/api/v1',
    filghtServices:
      live
        ? 'https://flightapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://flightapi.gemtechmyanmar.com/prod/api/v1',
    insuranceServices:
      live
        ? 'https://insuranceapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://insuranceapi.gemtechmyanmar.com/prod/api/v1',
  },
  encrypt_key: 'bqw868/SK5b0hbzd6TdWcxST9AV44eNwnAAcVTCB0P8='
}
