import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface bzChildValueProps {
  name: string;
  value: string;
}
export interface bzValueProps {
  value: string;
  account?: bzChildValueProps[];
}
export interface bzItemsProps {
  name: string;
  value: bzValueProps;
}

export interface exchangeRateProps {
  currfrom: string;
  currto: string;
  buy: string;
  sell: string;
}
interface commonState {
  bzLst?: bzItemsProps[];
  exchangeRates?: exchangeRateProps[];
}

const initialState: commonState = {
  bzLst: [],
  exchangeRates: []
};

export const commonSlice = createSlice({
  name: "bz",
  initialState,
  reducers: {
    setBzItems: (state, action: PayloadAction<bzItemsProps[]>) => {
      state.bzLst = action.payload;
    },
    setExchangeRates: (state, action: PayloadAction<exchangeRateProps[]>) => {
      state.exchangeRates = action.payload
    }
  }
});

export default commonSlice.reducer;
export const { setBzItems,setExchangeRates } = commonSlice.actions;
