import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../Components/common/B2BButton";
import { BInput } from "../../../Components/common/B2BInput";
import { b2bConfig } from "../../../Config";
import { Instance } from "../../../Services/instance";
import { setCredential } from "../../../Store/Reducers/auth";
import { AuthWrapper } from "../../../Templates/AuthWrapper";
import "./index.css";

interface authPageProps {
  className?: string;
}

interface loginProps {
  username: string;
  password: string;
}

export const AuthPage: React.FC<authPageProps> = ({ className }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<loginProps>({
    username: "",
    password: ""
  });
  const { addToast } = useToasts();
  const history = useHistory();
  const sumbitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-token-auth`,
        method: "POST",
        data: formData
      });
      if (response.status === 200) {
        addToast(`Login successful`, {
          appearance: "success",
          autoDismiss: true
        });
        if (response.data) {
          dispatch(
            setCredential({
              usrNm: formData.username,
              accessToken: response.data.access,
              refreshToken: response.data.refresh
            })
          );
          localStorage.setItem("accessToken", response.data.access);
          localStorage.setItem("refreshToken", response.data.refresh);
          history.push("/dashboard");
        }
      }
      if (response.status === 401) {
        addToast(`Oops , Something wrong!`, {
          appearance: "error",
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast(`Oops , Something wrong!`, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <AuthWrapper title="B2B LOGIN">
      <div className={`${className}`}>
        <form
          onSubmit={e => {
            sumbitLogin(e);
          }}
          className="flex w-full justify-center items-center content-center space-y-2 flex-col"
        >
          <BInput
            id="username"
            name="username"
            setFormData={setFormData}
            placeHolder="Enter user name"
            type="text"
            label="User Name"
            formData={formData}
            required={true}
          />
          <BInput
            id="password"
            formData={formData}
            name="password"
            setFormData={setFormData}
            placeHolder="Enter password"
            type="password"
            label="Password"
            required={true}
          />
          <Bbutton block type="submit" title="Login" />
        </form>
      </div>
    </AuthWrapper>
  );
};
