import React from "react";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../Templates/DashbaordInnerWrapper";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import { MobileTopupPage } from "./MobileTopups";

export const MobileAdminPage: React.FC = () => {
  const { path } = useRouteMatch();
  const menu: tabMenuProps[] = [
    {
      name: "Transactions",
      path: `${path}`
    }
  ];
  return (
    <DashbaordInnerWrapper tabMenus={menu}>
      <Switch>
        <Route exact path={`${path}`} component={MobileTopupPage} />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
