import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Store";
import { authState } from "../../../../Store/Reducers/auth";
import "./index.css";

interface profilePageProps {
  className?: string;
}

export const DashbaordProfile: React.FC<profilePageProps> = ({
  className,

}) => {
  const userData: authState = useSelector((state: RootState) => state.auth)
  return (
    <div className={`${className} w-full`}>
      <div className="grid grid-flow-row grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'> Business Name</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.bzNm}  </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Business Email</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.bzEmail} </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Business Address</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.bzAddress} </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Business City</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.bzCity} </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Business Country</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'>{userData.bzCountry}  </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Business Phone</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.bzPhone} </span>
        </div> <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'>Login Tm</span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <span className='text-secondary text-sm'> {userData.loginDt} {userData.loginTm} </span>
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className='text-secondary text-sm'> Modules </span>
        </div>
        <div className="col-span-12 md:col-span-7">
          <div className="flex flex-row space-x-2">
            {
              userData.modules?.map((m, i) => (
                <span className='text-secondary text-sm' key={i}>{m.moduleName} </span>
              ))
            }

          </div>
        </div>
      </div>
    </div>
  );
};
