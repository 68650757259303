import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../../../Components/common/B2BButton";
import { BCustomInput } from "../../../../../Components/common/B2BCustomInput";
import { BImageUploader } from "../../../../../Components/common/B2BImageUploader";
import { BRadio } from "../../../../../Components/common/B2BRadio";
import { BCheckBox } from "../../../../../Components/common/BCheckBox";
import { SinglePicker } from "../../../../../Components/common/DatePicker/Single";
import format from "date-fns/format";
import "./index.css";
import { Instance } from "../../../../../Services/instance";
import { b2bConfig } from "../../../../../Config";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";
import { BAutoComplete } from "../../../../../Components/common/B2BAutoComplete";
interface userAddProps {
  className?: string;
}

interface userFormProps {
  businessID: any;
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  dob: Date;
  mobileNo: string;
  address: string;
  city: string;
  country: string;
  status: string;
  userRole: string[];
  profilePhoto: string;
}

export const AddUserPage: React.FC<userAddProps> = ({ className }) => {
  const { bzLst } = useSelector((state: RootState) => state.common);
  const { addToast } = useToasts();
  const [formData, setFormData] = useState<userFormProps>({
    businessID: null,
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    dob: new Date(
      new Date().getFullYear() - 20,
      new Date().getMonth(),
      new Date().getDate()
    ),
    mobileNo: "",
    address: "",
    city: "",
    country: "",
    status: "ACTIVE",
    userRole: ["AGENT_ADMIN"],
    profilePhoto: ""
  });
  const submitUserForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let formObj: any = {};
      formObj["profilePhoto"] = formData.profilePhoto;
      formObj["businessID"] = formData.businessID.value.value;
      formObj["title"] = formData.title;
      formObj["firstName"] = formData.firstName;
      formObj["lastName"] = formData.lastName;
      formObj["emailAddress"] = formData.emailAddress;
      formObj["password"] = formData.password;
      formObj["dob"] = format(formData.dob, "yyyy-MM-dd");
      formObj["mobileNo"] = formData.mobileNo;
      formObj["address"] = formData.address;
      formObj["city"] = formData.city;
      formObj["country"] = formData.country;
      formObj["status"] = formData.status;
      formObj["userRole"] = formData.userRole;
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-create-user`,
        method: "POST",
        data: formObj
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            addToast("User added successfully", {
              appearance: "success",
              autoDismiss: true
            });
          } else {
            if (res.data.Error) {
              addToast(res.data.Error, {
                appearance: "warning",
                autoDismiss: true
              });
            }
          }
        })
        .catch(err => {
          addToast(`System error ${JSON.stringify(err)}`, {
            appearance: "warning",
            autoDismiss: true
          });
        })
        .finally(() => {
          setFormData({
            businessID: {
              name: bzLst ? bzLst[0].name : "",
              value: bzLst ? bzLst[0].value : { value: "", account: [] }
            },
            title: "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            password: "",
            dob: new Date(
              new Date().getFullYear() - 20,
              new Date().getMonth(),
              new Date().getDate()
            ),
            mobileNo: "",
            address: "",
            city: "",
            country: "",
            status: "ACTIVE",
            userRole: ["AGENT_ADMIN"],
            profilePhoto: ""
          });
        });
    } catch (error) {
      addToast(`System error ${JSON.stringify(error)}`, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div className={`${className}`}>
      <form
        onSubmit={e => {
          submitUserForm(e);
        }}
        className="w-full"
      >
        <div className="grid grid-cols-12 grid-flow-row gap-3">
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Profile Photo</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BImageUploader
              formData={formData}
              setFormData={setFormData}
              name="profilePhoto"
              id="profilePhoto"
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Business ID</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BAutoComplete
              formData={formData}
              setFormData={setFormData}
              name="businessID"
              id="businessID"
              items={bzLst}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Title</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="title"
              id="title"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">First Name</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="firstName"
              id="firstName"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Last Name</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="lastName"
              id="lastName"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Email Address</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="emailAddress"
              id="emailAddress"
              type="eamil"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Password</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="password"
              id="password"
              type="password"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">DOB</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <SinglePicker
              value={formData.dob}
              formData={formData}
              setFormData={setFormData}
              name="dob"
              id="dob"
              maxDate={
                new Date(
                  new Date().getFullYear() - 18,
                  new Date().getMonth(),
                  new Date().getDate()
                )
              }
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Mobile Number</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="mobileNo"
              id="mobileNo"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Address</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="address"
              id="address"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">City</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BCustomInput
              formData={formData}
              setFormData={setFormData}
              name="city"
              id="city"
              type="text"
              required={true}
            />
          </div>
          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">Status</span>
          </div>
          <div className="col-span-12 md:col-span-7">
            <BRadio
              radios={[
                { text: "ACTIVE", value: "ACTIVE" },
                { text: "INACTIVE", value: "INACTIVE" }
              ]}
              formData={formData}
              setFormData={setFormData}
              name="status"
              id="status"
              inline={true}
            />
          </div>

          <div className="col-span-12 md:col-span-5">
            <span className="text-secondary text-sm">User Role</span>
          </div>

          <div className="col-span-12 md:col-span-7">
            <BCheckBox
              formData={formData}
              setFormData={setFormData}
              name="userRole"
              id="userRole"
              options={[
                { value: "AGENT_ADMIN", label: "AGENT_ADMIN" },
                { value: "AGENT_USER", label: "AGENT_USER" }
              ]}
            />
          </div>
          <div className="col-span-12">
            <div className="flex py-2">
              <Bbutton title="Submit" type="submit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
