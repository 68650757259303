import React from "react";
import "./index.css";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { each } from "lodash";
export interface selectOptions {
  value: string | number;
  label: string;
}
export interface bCheckBoxProps {
  className?: string;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  options: OptionsOrGroups<string[], GroupBase<string[]>> | any;
  name: string;
  id?: string;
  label?: string;
  formData: any;
}

export const BCheckBox: React.FC<bCheckBoxProps> = ({
  className,
  setFormData,
  options,
  name,
  label,
  formData
}) => {
  const ChangeHandler = (value: any, name: string) => {
    let optionValues: string[] = [];
    each(value, v => {
      optionValues.push(v.value);
    });
    setFormData({ ...formData, [name]: optionValues });
  };
  return (
    <div className={`${className}`}>
      {label &&
        <label htmlFor={name}>
          {label}
        </label>}
      <Select
        defaultValue={[]}
        isMulti
        name={name}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={newValue => {
          ChangeHandler(newValue, name);
        }}
      />
    </div>
  );
};
