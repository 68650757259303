import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { BAutoComplete } from "../../../../Components/common/B2BAutoComplete";
import { Bbutton } from "../../../../Components/common/B2BButton";
import { BCustomInput } from "../../../../Components/common/B2BCustomInput";
import { BRadio } from "../../../../Components/common/B2BRadio";
import { BFileUploader } from "../../../../Components/common/BDocUploader";
import { BSelect } from "../../../../Components/common/BSelect";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import "./index.css";

interface debitPageProps {
  className?: string;
}

interface acctActionFormProps {
  "bizid": any;
  "accountID": string;
  "currency": string;
  "amount": string;
  "action": string;
  "remark": string;
  "ref": string;
  refAttach: string;
}
export const DebitPage: React.FC<debitPageProps> = ({ className }) => {
  const { bzLst } = useSelector((state: RootState) => state.common);
  const [debitForm, setDebitForm] = useState<acctActionFormProps>({
    bizid: undefined,
    accountID: "",
    currency: "",
    amount: "",
    action: "credit",
    remark: "",
    ref: "",
    refAttach: ""
  });
  const ResetAction = () => {
    setDebitForm({
      bizid: undefined,
      accountID: "",
      currency: "",
      amount: "",
      action: "credit",
      remark: "",
      ref: "",
      refAttach: ""
    });
  };
  const { addToast } = useToasts();
  const onSubmitDebitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let formObj: any = {};
      formObj["bizid"] = debitForm.bizid.value.value;
      formObj["accountID"] = debitForm.accountID
        ? debitForm.accountID
        : debitForm.bizid.value.account[0].value;
      formObj["currency"] = debitForm.currency ? debitForm.currency : "MMK";
      formObj["action"] = "debit";
      formObj["amount"] = debitForm.amount;
      formObj["remark"] = debitForm.remark;
      formObj["ref"] = debitForm.ref;
      if (debitForm.refAttach && debitForm.refAttach.startsWith("data:")) {
        formObj["refAttach"] = debitForm.refAttach;
      }
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-acct-action`,
        method: "POST",
        data: formObj
      })
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.Status.Code === "01") {
              addToast(`Success`, { appearance: "success", autoDismiss: true });
            } else if (res.data && res.data.Status.Code === "500") {
              addToast(`${JSON.stringify(res.data.Error)}`, {
                appearance: "warning",
                autoDismiss: true
              });
            }
          }
        })
        .catch(err => {
          addToast(`System error - ${JSON.stringify(err)}`, {
            appearance: "error"
          });
        })
        .finally(() => {
          ResetAction();
        });
    } catch (error) {
      addToast(`System error - ${JSON.stringify(error)}`, {
        appearance: "error"
      });
    }
  };
  return (
    <div className={`${className} container`}>
      <div className="w-ful max-w-screen-md">
        <form
          onSubmit={e => {
            onSubmitDebitForm(e);
          }}
          className="px-4 py-4 rounded-md shadow-md flex flex-col space-y-2"
        >
          <BAutoComplete
            title="Search Business"
            items={bzLst}
            formData={debitForm}
            setFormData={setDebitForm}
            name="bizid"
            id="bizid"
          />
          <BSelect
            formData={debitForm}
            label="Choose Account"
            setFormData={setDebitForm}
            name="accountID"
            id="accountID"
            options={
              debitForm.bizid &&
              debitForm.bizid.value &&
              debitForm.bizid.value.account
                ? debitForm.bizid.value.account
                : []
            }
          />
          <BCustomInput
            required={true}
            type="text"
            setFormData={setDebitForm}
            label="Enter Amount"
            name="amount"
            id="amount"
            formData={debitForm}
          />
          <div className="flex w-full space-x-2">
            <label htmlFor="currency" className="text-secondary text-sm">
              Choose Curreny
            </label>
            <BRadio
              inline
              radios={[
                { text: "MMK", value: "MMK" },
                { text: "USD", value: "USD" }
              ]}
              setFormData={setDebitForm}
              formData={debitForm}
              name="currency"
              id="currency"
            />
          </div>
          <BCustomInput
            required={true}
            type="text"
            setFormData={setDebitForm}
            label="Remark"
            name="remark"
            id="remark"
            formData={debitForm}
          />
          <BCustomInput
            required={true}
            type="text"
            setFormData={setDebitForm}
            label="Ref:"
            name="ref"
            id="ref"
            formData={debitForm}
          />
          <BFileUploader
            setFormData={setDebitForm}
            label="RefAttach:"
            name="refAttach"
            id="refAttach"
            formData={debitForm}
          />
          <div className="flex flex-start">
            <Bbutton title="Submit" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};
