import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { Store } from 'redux'
import { b2bConfig } from '../Config'
import { userDataPropps } from '../Pages/Dashboard/Home'
import { refreshToken, removeCredentials, setProfile } from '../Store/Reducers/auth'
import { setLoading } from '../Store/Reducers/loading'

export const Instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },

})

export const setupInterceptor = (store: Store) => {
  Instance.interceptors.request.use(
    config => {
      dispatch(setLoading(true))
      document.body.classList.add('loading-indicator');
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        config.headers['Authorization'] = `JWT ${accessToken}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  const { dispatch } = store
  Instance.interceptors.response.use(
    (res: AxiosResponse) => {
      dispatch(setLoading(false))
      
      const host = res.config.url
      if (host === `${b2bConfig.apiPath.bzEntry}/user/profile/`) {
        if (res.data) {
          const data: userDataPropps = res.data.Data
          // console.log(data, ['user_data'])
          dispatch(setProfile(data))
        }
        document.body.classList.remove('loading-indicator');
        return res
      }
      else {
        document.body.classList.remove('loading-indicator');
        return res
      }


    },
    async (err: AxiosError) => {
      dispatch(setLoading(false))
      document.body.classList.remove('loading-indicator');
      const originConfig = err.config
      if (originConfig.url !== 'https://b2bapi.gemtechmyanmar.com/dev/api/v1/admin-token-auth' && err.response) {
        if (err.response.status === 401) {
          try {
            const rs = await Instance.post('https://b2bapi.gemtechmyanmar.com/dev/api/v1/admin-token-auth/refresh', {
              refresh: localStorage.getItem('refreshToken')
            })
            localStorage.setItem('accessToken', rs.data.access)
            dispatch(refreshToken(rs.data.access))
            return Instance(originConfig)
          } catch (error) {
            dispatch(removeCredentials())
            localStorage.clear()
            return Promise.reject(error)
          }
        }
      }
    }
  )
}

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' }
): BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
  },
  unknown,
  unknown
> => async ({ url, method, data }) => {
  try {
    console.log(url)
    const result = await Instance({ url: baseUrl + url, method, data })
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError as AxiosError

    return {
      error: { status: err.response?.status, data: err.response?.data }
    }
  }
}