import React from "react";
import {Route, Switch,useRouteMatch} from 'react-router-dom'
import { AnnouncementAddPage } from "./Add";
import { AnnouncementLstPage } from "./List";
import { AnnouncementUpdatePage } from "./Update";
export const AnnouncementPage:React.FC = ()=>{
    const {path} = useRouteMatch()
    return(
        <Switch>
            <Route exact path={path} component={AnnouncementLstPage} />
            <Route exact path={`${path}/add`} component={AnnouncementAddPage} />
            <Route exact path={`${path}/update/:announcementId`} component={AnnouncementUpdatePage} />
        </Switch>
    )
}