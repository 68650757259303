import React from 'react'
import {Route,Switch,useRouteMatch} from 'react-router-dom'
import { DashbaordInnerWrapper, tabMenuProps } from '../../../Templates/DashbaordInnerWrapper'
import { AttractionBookingsPage } from './AttractionBookings'
import { AttractionPage } from './Attractions'
export const ActractionAdminPage:React.FC=()=>{
    const {path} = useRouteMatch()
    const menus:tabMenuProps[]=[
        {
            name:'Attractions',
            path:`${path}`
        },
        {
            name:'Attraction Bookings',
            path:`${path}/bookings`
        }
    ]
   
    return(
       <DashbaordInnerWrapper tabMenus={menus}>
           <Switch>
               <Route exact path={`${path}`} component={AttractionPage} />
               <Route exact path={`${path}/bookings`} component={AttractionBookingsPage} />
           </Switch>
       </DashbaordInnerWrapper>
    )
}