import React from "react";
import './index.css'

interface importBookingPageProps{
    className?:string
}

export const ImportBookingPage:React.FC<importBookingPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>IMport Booking Page </h3>
        </div>
    )
}