import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { DashbaordInnerWrapper, tabMenuProps } from '../../../Templates/DashbaordInnerWrapper'
import { BusBookingPage } from './BusBookings'
import { BusSearchPage } from './BusSearch'
export const BusAdminPage: React.FC = () => {
    const { path } = useRouteMatch()
    const menus: tabMenuProps[] = [
        {
            name: 'Bus Search',
            path: `${path}`
        },
        {
            name: 'Bus Bookings',
            path: `${path}/bus-bookings`
        }
    ]
    return (
        <DashbaordInnerWrapper tabMenus={menus} >
            <Switch>
                <Route exact path={`${path}`} component={BusSearchPage} />
                <Route exact path={`${path}/bus-bookings`} component={BusBookingPage} />
            </Switch>
        </DashbaordInnerWrapper>
    )
}