import React from "react";
import './index.css'

interface unauthenticatedPageProps{
    className?:string
}

export const UnauthenticatedPage:React.FC<unauthenticatedPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Unauthenticated Page </h3>
        </div>
    )
}