import React from "react";
import './index.css'

interface busSearchPageProps{
    className?:string
}

export const BusSearchPage:React.FC<busSearchPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Bus Search Page </h3>
        </div>
    )
}