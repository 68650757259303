import { AgGridColumnProps } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { BAutoComplete } from '../../../../../Components/common/B2BAutoComplete'
import { Bbutton } from '../../../../../Components/common/B2BButton'
import { BCustomInput } from '../../../../../Components/common/B2BCustomInput'
import { BDataGrid } from '../../../../../Components/common/B2BDataGrid'
import { BPopup } from '../../../../../Components/common/B2BPopup'
import { BRadio } from '../../../../../Components/common/B2BRadio'
import { b2bConfig } from '../../../../../Config'
import { Instance } from '../../../../../Services/instance'
import { RootState } from '../../../../../Store'
import { bzValueProps } from '../../../../../Store/Reducers/common'
import './index.css'
interface userLstProps {
  className?: string
}

export interface userProps {
  bizid: string
  bizname: string
  title: string
  firstname: string
  lastname: string
  email: string
  userrole: string[]
  dob: string
  mobileno: string
  photourl: string
  address: string
  city: string
  country: string
}
interface formDataPorps {
  businessId: bzValueProps
}
export const AvactorRender = (props: any) => {
  return props && props.data && props.data.photourl ? (
    <>
      <img
        src={props.data.photourl}
        alt=''
        className='w-auto h-8 rounded-full'
      />
    </>
  ) : (
    <></>
  )
}

const columnDef: AgGridColumnProps[] = [
  {
    headerName: 'Biz Id',
    field: 'bizid',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Biz Name',
    field: 'bizname',
    cellClass: 'text-secondary text-sm',
    width: 150
  },
  {
    headerName: 'Title',
    field: 'title',
    cellClass: 'text-secondary text-sm',
    maxWidth: 75
  },
  {
    headerName: 'First Name',
    field: 'firstname',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Last Name',
    field: 'lastname',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Email',
    field: 'email',
    cellClass: 'text-secondary text-sm',
    width: 150
  },
  {
    headerName: 'Roles',
    cellClass: 'text-secondary text-sm',
    field: 'userrole',
    autoHeight: true,
    width: 150
  },
  {
    headerName: 'DOB',
    field: 'dob',
    cellClass: 'text-secondary text-sm',
    maxWidth: 150
  },
  {
    headerName: 'Avactor',
    field: 'photourl',
    cellRendererFramework: AvactorRender,
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Mobile No.',
    field: 'mobileno',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Address',
    field: 'address',
    cellClass: 'text-secondary text-sm'
  },
  {
    headerName: 'City',
    field: 'city',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  },
  {
    headerName: 'Country',
    field: 'country',
    cellClass: 'text-secondary text-sm',
    maxWidth: 100
  }
]
interface editFormProps {
  userid: string
  username: string
  title: string
  firstname: string
  lastname: string
  password: string
  status: string
}
export const UserLstPage: React.FC<userLstProps> = ({ className }) => {
  const [users, setUsers] = useState<userProps[]>([])
  const { isAuth } = useSelector((state: RootState) => state.auth)
  const { bzLst } = useSelector((state: RootState) => state.common)
  const [formData, setFormData] = useState<formDataPorps>()
  const [editForm, setEditForm] = useState<editFormProps>({
    userid: '',
    username: '',
    title: '',
    firstname: '',
    lastname: '',
    password: '',
    status: ''
  })

  const [show, setShow] = useState<boolean>(false)
  const { addToast } = useToasts()
  const EditForm = (data: any) => {
    setShow(!show)

    setEditForm({
      firstname: data.firstname,
      lastname: data.lastname,
      userid: data.userid,
      title: data.title,
      username: data.username,
      password: data.password,
      status: 'ACTIVE'
    })
  }
  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      let formObj: any = {}
      formObj['firstname'] = editForm.firstname
      formObj['lastname'] = editForm.lastname
      formObj['userid'] = editForm.userid
      formObj['title'] = editForm.title
      if (editForm?.password) {
        formObj['password'] = editForm.password
      }
      if (editForm?.status) {
        formObj['status'] = editForm.status
      }
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-update-user`,
        method: 'POST',
        data: editForm
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            addToast("User added successfully", {
              appearance: "success",
              autoDismiss: true
            });
          } else {
            if (res.data.Error) {
              addToast(res.data.Error, {
                appearance: "warning",
                autoDismiss: true
              });
            }
          }
        })
        .catch(err => {
          addToast(`System error ${JSON.stringify(err)}`, {
            appearance: "warning",
            autoDismiss: true
          });
        })
        .finally(() => {
          setEditForm({
            userid: '',
            username: '',
            title: '',
            firstname: '',
            lastname: '',
            password: '',
            status: ''
          })
          setShow(!show)
        });
    } catch (error) {
      addToast(`System error ${JSON.stringify(error)}`, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  useEffect(() => {
    if (isAuth) {
      if (formData?.businessId.value) {
        const businessId: any = formData.businessId.value
        if (businessId.value) {
          Instance({
            url: `${b2bConfig.apiPath.bzEntry}/admin-user-list?bizid=${businessId.value}`
          })
            .then(res => {
              if (
                res.data &&
                res.data.Data &&
                res.data.Status &&
                res.data.Status.Code === '01'
              ) {
                const response: userProps[] = res.data.Data
                setUsers(response)
              }
            })
            .catch(err => {
              console.log(err.message)
            })
        }
      } else {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/admin-user-list`
        })
          .then(res => {
            if (
              res.data &&
              res.data.Data &&
              res.data.Status &&
              res.data.Status.Code === '01'
            ) {
              const response: userProps[] = res.data.Data
              setUsers(response)
            }
          })
          .catch(err => {
            console.log(err.message)
          })
      }
    }
  }, [isAuth, formData])
  return (
    <div className={`${className}`}>
      <div className='w-full'>
        <BAutoComplete
          title='Select Business'
          formData={formData}
          setFormData={setFormData}
          name='businessId'
          id='businessId'
          items={bzLst}
          className='w-full max-w-md my-2'
        />
      </div>
      <div className='w-full'>
        <BDataGrid
          onRowDoubleClicked={e => EditForm(e.data)}
          columnDefs={columnDef}
          rowData={users}
          pagination={true}
          paginationAutoPageSize={false}
          paginationPageSize={10}
          className='b2b_usr_lst my-4'
        />
      </div>
      <div>
        <BPopup show={show} setShow={setShow} title='User Edit '>
          <form
            onSubmit={e => {
              SubmitForm(e)
            }}
            className='w-full flex flex-col space-y-2 px-2 py-2'
          >
            <BCustomInput
              label='Title'
              name='title'
              id='title'
              setFormData={setFormData}
              type='text'
              formData={editForm}
            />
            <BCustomInput
              label='FirstName'
              name='firstname'
              id='firstname'
              setFormData={setFormData}
              type='text'
              formData={editForm}
            />
            <BCustomInput
              label='LastName'
              name='lastname'
              id='lastname'
              setFormData={setFormData}
              type='text'
              formData={editForm}
            />
            <BCustomInput
              label='Password'
              name='password'
              id='password'
              setFormData={setFormData}
              type='password'
              formData={editForm}
              required={false}
            />

            <div>
              <label className='text-sm text-secondary' htmlFor='status'>
                Status
              </label>
              <BRadio
                formData={editForm}
                inline={true}
                setFormData={setFormData}
                name='status'
                id='status'
                radios={[
                  { value: 'ACTIVE', text: 'ACTIVE' },
                  { value: 'INACTIVE', text: 'INACTIVE' }
                ]}
              />
            </div>
            <div className='flex flex-row justify-start space-x-2'>
              <Bbutton type='submit' title='Update' color='success' />
              <Bbutton
                type='button'
                title='Cancel'
                color='danger'
                NextFunction={() => {
                  setShow(!show)
                }}
              />
            </div>
          </form>
        </BPopup>
      </div>
    </div>
  )
}
