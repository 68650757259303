import React, { useState } from 'react'
import { GroupBase, OptionsOrGroups } from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { Bbutton } from '../../../../Components/common/B2BButton';
import { radioDataProps } from '../../../../Components/common/B2BRadio';
import { AddBusiness } from '../../../../Components/dashbaord/BizAdmin/AddBusiness'
import { b2bConfig } from '../../../../Config';
import { Instance } from '../../../../Services/instance';
import './index.css'

interface addBusinessPageProps {
  className?: string
}

export interface businessFormDataProps {
  businessID: string
  businessName: string
  businessEmail: string
  businessPhone: string
  businessCity: string
  businessCountry: string
  businessLogo: string
  businessStatus: string
  businessService:string []
  businessType: string,
  businessAddress:string
}

export const businessStatusRadioData: radioDataProps[] = [
  {
    value: "ACTIVE",
    text: "Active"
  },
  {
    value: "INACTIVE",
    text: "Inactive"
  }
];
export const businessTypeRadioData: radioDataProps[] = [
  {
    value: "Agent",
    text: "Agent"
  },
  {
    value: "Supplier",
    text: "Supplier"
  },

];

export const businessServicesOptions:OptionsOrGroups<string[],GroupBase<string[]>>|any = [
 
  {
    label: "Flight",
    value: "flight"
  },
  {
    label: "Bus",
    value: "bus"
  },
  {
    label: "Tour",
    value: "tour"
  },
  {
    label: "Mobile",
    value: "mobile"
  },
  {
    label: "Insurance",
    value: "insurance"
  },
  {
    label: "Car-rental",
    value: "car-rental"
  }
];

export const AddBusinessPage: React.FC<addBusinessPageProps> = ({
  className
}) => {
  const [formData, setFormData] = useState<businessFormDataProps>({
    businessID: '',
    businessLogo: '',
    businessName: '',
    businessPhone: '',
    businessCity: '',
    businessCountry: '',
    businessService: [],
    businessStatus: 'ACTIVE',
    businessType: 'Agent',
    businessEmail: '',
    businessAddress:''
  })

  const {addToast} = useToasts()

  const SubmitForm = (e:React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault()
      try {
        Instance({
          url:`${b2bConfig.apiPath.bzEntry}/admin-create-business`,
          method:'POST',
          data:formData
        })
        .then(res=>{
          if (res.status === 200) {
            if (
              res.data &&
              res.data.Status.Code === "01"
            ) {
              setFormData({
                businessID: '',
                businessLogo: '',
                businessName: '',
                businessPhone: '',
                businessCity: '',
                businessCountry: '',
                businessService: [],
                businessStatus: 'ACTIVE',
                businessType: 'Agent',
                businessEmail: '',
                businessAddress:''
              })
              addToast(`New business added successfully!`,{appearance:'success'});
            }
            if (res.data && res.data.Error) {
              addToast(`${JSON.stringify(res.data.Error)}`, { appearance: 'warning' })
            }
          }
        })
        .catch(err=>{
            addToast(`${JSON.stringify(err)}`,{appearance:'warning'})
        })
      } catch (error) {
        addToast(`System error`, { appearance: "error" });
      }
  }
  return (
    <div className={`${className} w-full px-2`}>
      <form onSubmit={(e)=>{SubmitForm(e)}}>
      <AddBusiness formData={formData} setFormData={setFormData}  />
      <div className='flex justify-start'>
        <Bbutton  type='submit' title='Save'  />
      </div>
      </form>
    </div>
  )
}
