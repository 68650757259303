import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../Templates/DashbaordInnerWrapper";
import { BookingDetail } from "./BookingDetail";
import { BuyInsurancePage } from "./BuyInsurance";
import { InsuranceBookingPage } from "./InsuranceBookings";

export const InsureanceAdminPage: React.FC = () => {
  const { path } = useRouteMatch();

  const menu: tabMenuProps[] = [
    {
      name: "Buy Insurance",
      path: `${path}`
    },
    {
      name: "Insurance Bookings",
      path: `${path}/insurance_bookings`
    }
  ];

  return (
    <DashbaordInnerWrapper tabMenus={menu}>
      <Switch>
        <Route exact path={`${path}`} component={BuyInsurancePage} />
        <Route
          exact
          path={`${path}/insurance_bookings`}
          component={InsuranceBookingPage}
        />
        <Route
          exact
          path={`${path}/booking-detail/:id`}
          component={BookingDetail}
        />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
