import React from 'react'
import './App.css'
import { Provider as StoreProvider } from 'react-redux'
import { store } from './Store'
import { RootRouter } from './routes'
import { ToastProvider } from 'react-toast-notifications'
import { setupInterceptor } from './Services/instance'
export const B2BAdmin: React.FC = () => {
  return (
    <div className='root'>
      <StoreProvider store={store}>
        <ToastProvider placement='top-center'>
          <RootRouter />
        </ToastProvider>
      </StoreProvider>
    </div>
  )
}

setupInterceptor(store)
