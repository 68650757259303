import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../Components/common/SelectTable";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import "./index.css";

interface businessListPageProps {
  className?: string;
}

export interface businessProps {
  businessAddress?: string;
  businessCity?: string;
  businessEmail?: string;
  businessID?: string;
  businessLogo?: string;
  businessName?: string;
  businessPhone?: string;
  businessServices?: string[];
  businessStatus?: string;
  businessType?: string;
  businessCountry?: string;
  pk?: string;
  businessAccount?: businessAccountProps[];
  businessBalance?: businessBalanceProps[];
}

export interface businessBalanceProps {
  accountID: string;
  balance: number;
  currency: string;
  updateDate: string;
}

export interface businessAccountProps {
  accountID: string;
  balance: number;
  currency: string;
}

export const BusinessListPage: React.FC<businessListPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [businessList, setBusinessList] = useState<businessProps[]>([]);
  const history = useHistory();
  const { path } = useRouteMatch();
  useEffect(
    () => {
      if (localStorage.getItem("accessToken")) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/admin-bizlist`,
          method: "POST"
        })
          .then(res => {
            if (res.status === 200) {
              if (res.data && res.data.Data) {
                const reposneData: businessProps[] = res.data.Data;
                setBusinessList(reposneData);
              }
            }
          })
          .catch(err => {
            console.log(err, ["b2b_busiess_list_error"]);
          })
          .finally(() => {
            console.log("[business_lst_response]");
          });
      }
    },
    [isAuth]
  );
  return (
    <div className={`${className}`}>
      <SelectTable className="text-gray-800">
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Name</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Address</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>City</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Email</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Phone</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Status</span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400 bg-white">
          {businessList.map((b, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {i + 1}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessID}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessName}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessAddress}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessCity}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessEmail}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessPhone}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {b.businessStatus}
                </span>
              </TableCell>
              <TableCell>
                <button
                  onClick={() => {
                    history.push(`${path}/biz-detail/${b.businessID}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>
    </div>
  );
};
