import React from "react";
import './index.css'

interface rentalBookingsPageProps{
    className?:string
}

export const RentalBookingsPage:React.FC<rentalBookingsPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Rental Bookings Page </h3>
        </div>
    )
}