import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import format from "date-fns/format";
import "./index.css";
import { BPopup } from "../../../../Components/common/B2BPopup";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/adventure_time.css";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../Components/common/SelectTable";
interface onlineFlightBookingsPageProps {
  className?: string;
}

export interface tempReponse {
  bookDate: string;
  bookRequestData: string;
  bookResponseData: string;
  bookingID: string;
  fareSource: string;
  fareSourceCode: string;
  pk: number;
  searchID: string;
}

export const OnlineFlightBookingPage: React.FC<
  onlineFlightBookingsPageProps
> = ({ className }) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [reponseData, setReponseData] = useState<tempReponse[]>([]);
  const [tempData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const fetchFlightBookings = useCallback(
    () => {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath.filghtServices}/booking-list`,
          method: "GET"
        })
          .then(res => {
            if (res.data && res.data.Data) {
              const tempData: tempReponse[] = res.data.Data;
              setReponseData(tempData);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [isAuth]
  );
  useEffect(
    () => {
      fetchFlightBookings();
    },
    [fetchFlightBookings]
  );
  const ShowData = () => {
    setShow(!show);
  };
  return (
    <div className={`${className}`}>
      <SelectTable>
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Fare Source</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Search ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400 bg-white">
          {reponseData.map((r, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {" "}{i + 1}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {" "}{r.bookingID}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {" "}{r.fareSource}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {" "}{r.searchID}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {" "}{r.bookDate &&
                    format(new Date(r.bookDate), "yyyy-MM-dd")}{" "}
                </span>
              </TableCell>
              <TableCell>
                <button
                  onClick={() => {
                    ShowData();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>

      <BPopup show={show} setShow={setShow} title="Online Booking">
        <div className="w-full flex flex-col px-2 py-2 ">
          <JSONPretty id="json-pretty" data={tempData} />
        </div>
      </BPopup>
    </div>
  );
};
