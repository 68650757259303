import React, { useState } from "react";
import "./index.css";
import FileIcon from "../../../assets/file.png";
import DocIcon from "../../../assets/doc.png";
interface fileUploaderProps {
  className?: string;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  name: string;
  id: string;
  label?: string;
  readOnly?: boolean;
  required?: boolean;
  placeHolder?: string;
  formData: any;
}

export const BFileUploader: React.FC<fileUploaderProps> = ({
  className,
  label,
  setFormData,
  name,
  id,
  readOnly,
  required,
  placeHolder,
  formData
}) => {
  const [fileName, setFileName] = useState("");
  const fileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    let reader = new FileReader();

    if (files) {
      reader.readAsDataURL(files[0]);
      setFileName(files[0].name);
    }
    reader.onload = e => {
      if (e.target && e.target.result) {
        setFormData({ ...formData, [event.target.name]: e.target.result });
      }
    };
  };

  return (
    <div className={`${className} w-full`}>
      <label className="text-sm text-secondary ml-2" htmlFor={name}>
        {label}
      </label>

      <div className="flex justify-start w-full">
        <div className="rounded-lg shadow-xl bg-gray-50">
          <div className="m-4">
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                {formData[name] &&
                  formData[name].startsWith("data:image") &&
                  <div className="flex flex-col items-center justify-center pt-7">
                    <img
                      src={formData[name]}
                      alt="b2blogo"
                      className="w-auto h-16"
                    />
                    <p className="text-xs text-primary">
                      <em>
                        {fileName}
                      </em>
                    </p>
                  </div>}
                {formData[name] &&
                  formData[name].startsWith("data:application") &&
                  <div className="flex flex-col items-center justify-center pt-7">
                    <img src={DocIcon} className="w-auto h-16" alt="doc" />
                    <p className="text-xs text-primary">
                      <em>
                        {fileName}
                      </em>
                    </p>
                  </div>}

                {!formData[name] &&
                  <div className="flex flex-col items-center justify-center pt-7">
                    <img src={FileIcon} alt="" className="w-auto h-12" />
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Select a file
                    </p>
                  </div>}

                <input
                  type="file"
                  onChange={e => {
                    fileHandler(e);
                  }}
                  placeholder={placeHolder}
                  id={id}
                  name={name}
                  readOnly={readOnly}
                  required={required}
                  className="opacity-0"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
