import React from "react";

import { BCustomInput } from "../../../common/B2BCustomInput";
import { BImageUploader } from "../../../common/B2BImageUploader";
import { BCheckBox } from "../../../common/BCheckBox";

import "./index.css";

import { BRadio } from "../../../common/B2BRadio";
import {
  businessFormDataProps,
  businessServicesOptions,
  businessStatusRadioData,
  businessTypeRadioData
} from "../../../../Pages/Dashboard/BusinessAdmin/AddBusiness";

interface addBusinessProps {
  className?: string;
  formData: businessFormDataProps;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

export const AddBusiness: React.FC<addBusinessProps> = ({
  className,
  formData,
  setFormData
}) => {
  return (
    <div className={`${className} w-full`}>
      <div className="grid grid-flow-row grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Logo</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BImageUploader
            name="businessLogo"
            id="businessLogo"
            setFormData={setFormData}
            formData={formData}
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business ID</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessID"
            id="businessID"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Name</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessName"
            id="businessName"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Email</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessEmail"
            id="businessEmail"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Address</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessAddress"
            id="businessAddress"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business City</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessCity"
            id="businessCity"
            setFormData={setFormData}
            type="text"
          />
        </div>{" "}
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Country</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessCountry"
            id="businessCountry"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Phone</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCustomInput
            formData={formData}
            name="businessPhone"
            id="businessPhone"
            setFormData={setFormData}
            type="text"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Status</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BRadio
            setFormData={setFormData}
            inline={true}
            name="businessStatus"
            id="businessStatus"
            radios={businessStatusRadioData}
            formData={formData}
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Service</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BCheckBox
            formData={formData}
            options={businessServicesOptions}
            setFormData={setFormData}
            name="businessService"
            id="businessService"
          />
        </div>
        <div className="col-span-12 md:col-span-5">
          <span className="text-secondary text-sm">Business Type</span>
        </div>
        <div className="col-span-6 md:col-span-7">
          <BRadio
            name="businessType"
            id="businessType"
            formData={formData}
            setFormData={setFormData}
            inline={true}
            radios={businessTypeRadioData}
          />
        </div>
      </div>
    </div>
  );
};
