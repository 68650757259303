import React from "react";
import { DashbaordProfile } from "../../../../Components/dashbaord/Main/Profile";
import "./index.css";

interface profilePageProps {
  className?: string;
}
export const ProfilePage: React.FC<profilePageProps> = ({ className }) => {
  return (
    <div className={`${className}`}>
      <DashbaordProfile />
    </div>
  );
};
