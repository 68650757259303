import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface authState {
  usrNm?: string
  usrId?: string
  bzNm?: string
  bzEmail?: string
  bzPhone?: string
  bzAddress?: string
  usrRole?: string
  bzCity?: string
  bzCountry?: string
  accessToken?: string
  refreshToken?: string
  isAuth: boolean,
  loginDt:string,
  loginTm:string,
  modules?:userModuleProps[]
}

export interface userModuleProps{
    moduleName:string
    moduleURL:string
    pk:string
}
export interface userDataPropps {
    ID?:string
    userEmail?:string
    userName?:string
    userRole?:string
    userModule?:userModuleProps[]
}

export interface setCredentials{
    usrNm?:string
    accessToken?:string
    refreshToken?:string
    loginTm?:string
}

const initialState:authState={
    usrId:'',
    usrNm:'',
    bzNm:'',
    bzEmail:'',
    bzPhone:'',
    bzAddress:'',
    usrRole:'',
    bzCity:'',
    bzCountry:'',
    accessToken:'',
    refreshToken:'',
    isAuth:false,
    loginDt:new Date().toDateString(),
    loginTm:new Date().toTimeString(),
    modules:[]
    
}

export interface credentialProps{
    usrNm:string
    accessToken:string
    refreshToken:string
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setCredential:(state,action:PayloadAction<credentialProps>)=>{
            state.usrNm=action.payload.usrNm 
            state.accessToken=action.payload.accessToken 
            state.refreshToken=action.payload.refreshToken
            state.loginDt = new Date().toDateString()
            state.loginTm = new Date().toTimeString()
            state.isAuth=true
        },
        removeCredentials:(state,action:PayloadAction)=>{
            state = initialState
        },
        refreshToken:(state,action:PayloadAction<string>)=>{
            state.accessToken=action.payload
        },
        setProfile:(state,action:PayloadAction<userDataPropps>)=>{
            state.accessToken=JSON.stringify(localStorage.getItem('accessToken'))
            state.refreshToken=JSON.stringify(localStorage.getItem('refreshToken'))
            state.modules=action.payload.userModule
            state.bzEmail=action.payload.userEmail
            state.usrRole=action.payload.userRole
            state.isAuth=true 
            state.usrId=action.payload.ID
            state.loginDt=new Date().toDateString()
            state.loginTm=new Date().toLocaleTimeString()
        }
    }
})

export const {setCredential,removeCredentials,refreshToken,setProfile} = authSlice.actions

export default authSlice.reducer
