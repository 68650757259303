import React from "react";
import './index.css'

interface hotelSearchPageProps{
    className?:string
}

export const HotelSearchPage:React.FC<hotelSearchPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Hotel Search Page </h3>
        </div>
    )
}