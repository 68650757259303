import { AgGridColumnProps } from 'ag-grid-react'
import React from 'react'
import { businessProps } from '../../../../../Pages/Dashboard/BusinessAdmin/BusinessList'
import { BDataGrid } from '../../../../common/B2BDataGrid'
import './index.css'
import format from 'date-fns/format'
interface showBusinessProps {
  data?: businessProps
  className?: string
}

export const ShowBusiness: React.FC<showBusinessProps> = ({
  className,
  data
}) => {
    const columns:AgGridColumnProps[]=[
        {
            headerName:'Account ID',
            field:'accountID',
            width:200
        },
        {
            headerName:'Balance',
            field:'balance'
        },
        {
            headerName:'Currency',
            field:'currency'
        },
        {
            headerName:'Updated At',
            field:'updateDate',
            width:300,
            cellRenderer:(param)=>{
             
              return(
                format(new Date(param.value),`yyyy-MM-dd' 'HH:mm:ss`)
              )
            }
        }
    ]
  return (
    <div className={`${className}`}>
      {data && (
        <div className='w-full'>
          <img
            src={data.businessLogo}
            alt={data.businessName}
            className='h-16 rounded-full w-auto object-contain'
          />
          <div className='grid grid-flow-row grid-cols-12 gap-3'>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business ID</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessID}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Name</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessName}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Email</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessEmail}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Phone</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessPhone}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Services</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessServices}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Status</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessStatus}
              </span>
            </div>
            <div className='col-span-5'>
              <span className='text-sm text-secondary'>Business Type</span>
            </div>
            <div className='col-span-7'>
              <span className='text-sm font-bold text-primary'>
                {data.businessType}
              </span>
            </div>
          </div>
          <hr className='bg-white text-white mb-2 mt-2' />
          <h6 className='text-primary text-sm font-bold'>Business Balance</h6>
          {
              
              data.businessBalance &&
              <BDataGrid className='w-full max-w-screen-lg h-40' columnDefs={columns} rowData={data.businessBalance} pagination={false}   />
          }
        </div>
      )}
    </div>
  )
}
