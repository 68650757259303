import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BAutoComplete } from "../../../../Components/common/B2BAutoComplete";
import { BDataGrid } from "../../../../Components/common/B2BDataGrid";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import { bzChildValueProps, bzItemsProps } from "../../../../Store/Reducers/common";
import format from 'date-fns/format'
import { each } from 'lodash'
import "./index.css";
import { AgGridColumnProps } from "ag-grid-react";
import { BSelect } from "../../../../Components/common/BSelect";
import { RangePicker } from "../../../../Components/common/DatePicker/Range";
interface bizDataProps {
  businessID?: string;
  businessName?: string;
  businessEmail?: string;
  businessPhone?: string;
  businessAddress?: string;
  businessCity?: string;
  businessCountry?: string;
  businessLogo?: string;
  businessStatus?: string;
  businessType?: string
  businessBalance?: bizBalanceProps[];
  businessService?: string[];
}

interface bizBalanceProps {
  accountID: string
  balance: number
  currency: string
  updateDate: string

}
interface transactionFormProps {
  accountId: string
  range: Date[]
}
interface businessTransactionsPageProps {
  className?: string;
}
interface businessFormDataProps {
  business: bzItemsProps;
}
const columnDef: AgGridColumnProps[] = [
  {
    headerName: 'Account ID',
    field: 'accountID',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'Balance',
    field: 'balance',
    cellClass: 'text-sm  text-secondary',
    maxWidth: 150,
    headerClass: ' text-primary'
  },
  {
    headerName: 'Currency',
    field: 'currency',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'Updated At',
    field: 'updateDate',
    cellClass: 'text-sm  text-secondary',
    valueFormatter: (value) => format(new Date(value.value), 'yyyy-MM-dd'),
    headerClass: ' text-primary'
  },

]

interface transactionDataProps {
  amount: number
  balance: number
  businessAccount: number
  createDate: string
  createdBy: string
  description: string
  pk: number
  refNo: string
  transactionType: string
}

const transactionColDef: AgGridColumnProps[] = [
  {
    headerName: 'Amount',
    field: 'amount',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'Balance',
    field: 'balance',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'Business Account',
    field: 'businessAccount',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'createDate',
    field: 'createDate',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary',
    valueFormatter: (value) => format(new Date(value.value), 'yyyy-MM-dd')
  },
  {
    headerName: 'createdBy',
    field: 'createdBy',
    maxWidth: 150,
    cellClass: 'text-sm  text-secondary',
    headerClass: ' text-primary'
  },
  {
    headerName: 'description',
    field: 'description',
    cellClass: 'text-sm  text-secondary',

    headerClass: ' text-primary'
  },
  {
    headerName: 'refNo',
    field: 'refNo',
    cellClass: 'text-sm  text-secondary',

    headerClass: ' text-primary'
  },
  {
    headerName: 'transactionType',
    field: 'transactionType',
    cellClass: 'text-sm  text-secondary',
    maxWidth: 150,
    headerClass: ' text-primary'
  },
]
export const BusinessBalancePage: React.FC<businessTransactionsPageProps> = ({
  className
}) => {
  const { bzLst } = useSelector((state: RootState) => state.common);
  const [businessFormData, setBusinessFormData] = useState<
    businessFormDataProps
  >();
  const [bizData, setBizData] = useState<bizDataProps>();
  const [accountOptions, setaccountOptions] = useState<bzChildValueProps[]>([])
  const [transactionForm, setTransactionForm] = useState<transactionFormProps>({
    accountId: '',
    range: [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
      new Date()
    ]
  })
  const [transactionsData, settransactionsData] = useState<transactionDataProps[]>([])

  const businessId = businessFormData
    ? businessFormData.business.value.value
    : "";
  useEffect(
    () => {
      if (businessId) {
        Instance({
          url: `${b2bConfig.apiPath
            .bzEntry}/admin-business-detail/${businessId}`,
          method: "GET"
        })
          .then(res => {
            if (
              res.data &&
              res.data.Data &&
              res.data.Status &&
              res.data.Status.Code === "01"
            ) {
              const response: bizDataProps = res.data.Data;
              setBizData(response);
              settransactionsData([])
              let options: bzChildValueProps[] = [{
                name: 'All',
                value: ''
              }]
              if (res.data.Data && res.data.Data.businessBalance) {
                const bizBalances = res.data.Data.businessBalance
                each(bizBalances, b => {
                  const params: bzChildValueProps = {
                    name: b.accountID,
                    value: b.accountID
                  }
                  options.push(params)
                })
                setaccountOptions(options)
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {

          })
      }
    },
    [businessId]
  );
  useEffect(() => {
    if (transactionForm.range && transactionForm.range[0] && transactionForm.range[1]) {
      let formObj: any = {}
      formObj['accountid'] = transactionForm.accountId
      formObj['fromdate'] = format(transactionForm.range[0], 'yyyy-MM-dd')
      formObj['todate'] = format(transactionForm.range[1], 'yyyy-MM-dd')
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-acct-biztransactions`,
        method: 'POST',
        data: formObj
      })
        .then(res => {
          if (res.data && res.data.Data && res.data.Status && res.data.Status.Code === '01') {
            const reponse: transactionDataProps[] = res.data.Data
            settransactionsData(reponse)
          }
          else {
            settransactionsData([])
          }
        })
        .catch(err => {
          console.log(err, ['system_error_trasactions'])
          settransactionsData([])
        })
    }
  }, [transactionForm])

  return (
    <div className={`${className} w-full`}>
      <form className="w-full flex max-w-md">
        <BAutoComplete
          name="business"
          formData={businessFormData}
          setFormData={setBusinessFormData}
          items={bzLst}
          id="business"
          title="Select Business"
          
        />
      </form>
      {
        bizData &&
        <div className="w-full my-4">

          <div className="grid grid-flow-row grid-cols-12 gap-3 shadow-md rounded-sm px-4 py-4" >
            <div className="col-span-12">
              <img src={bizData.businessLogo} alt="" className='rounded-lg w-auto h-14' />
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business ID</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessID} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Name</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessName} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Email</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessEmail} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Phone</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessPhone} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Address</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessAddress} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business City</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessCity} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Country</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessCountry} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Status</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessStatus} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Services</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessService} </span>
            </div>
            <div className="col-span-12 md:col-span-3">
              <span>Business Type</span>
            </div>
            <div className="col-span-12 md:col-span-9">
              <span> {bizData?.businessType} </span>
            </div>
            <hr className="my-4" />
            <div className="col-span-12">
              <h6 className="text-lg font-normal text-primary underline px-2 py-2">Business Balance</h6>
              <BDataGrid columnDefs={columnDef} rowData={bizData.businessBalance} className="w-full max-w-2xl h-40 " />
            </div>
            <hr className="my-4" />
            <div className="col-span-12">
              <h6 className="text-lg font-normal text-primary underline px-2 py-2">Transactions History</h6>
              <form>
                <div className="grid grid-flow-row grid-cols-12 gap-3">
                  <div className="col-span-12 md:col-span-4">
                    <BSelect label="Select Account" name="accountId" id='accountId'  setFormData={setTransactionForm} formData={transactionForm} options={accountOptions} />
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <RangePicker range={transactionForm.range} maxDate={new Date(new Date().getFullYear() + 20, new Date().getMonth(), new Date().getDate())} minDate={new Date(new Date().getFullYear() - 20, new Date().getMonth(), new Date().getDate())} setRange={setTransactionForm} name="range" id='range' formData={transactionForm} title="From~To" />
                  </div>
                </div>
              </form>
            </div>
            <hr className="my-2" />
            <div className="col-span-12">
              <div className="w-full">
                <BDataGrid className="w-full max-w-6xl h-96" columnDefs={transactionColDef} rowData={transactionsData} pagination={true} paginationAutoPageSize={false} paginationPageSize={10} />
              </div>
            </div>

          </div>
        </div>
      }

    </div>
  );
};
