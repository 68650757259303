import React from "react";
import { bzChildValueProps } from "../../../Store/Reducers/common";
import "./index.css";

export interface selectOptions {
  value: string | number;
  name: string;
}
export interface bSelectProps {
  className?: string;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  options: bzChildValueProps[];
  name: string;
  id: string;
  label?: string;
  formData: any;
}

export const BSelect: React.FC<bSelectProps> = ({
  className,
  setFormData,
  options,
  name,
  id,
  label,
  formData
}) => {
  return (
    <div className={`${className}`}>
      {label &&
        <label className="text-sm text-secondary" htmlFor={name}>
          {label}
        </label>}
      <select
        className="appearance-none  relative block w-full px-3 py-2 border border-gray-300 bg-white placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
        name={name}
        id={id}
        value={formData[name]}
        onChange={e => {
          setFormData({ ...formData, [e.target.name]: e.target.value });
        }}
      >
        {options.map((o: selectOptions, i: number) =>
          <option key={i} value={o.value}>
            {o.name}{" "}
          </option>
        )}
      </select>
    </div>
  );
};
