import React from "react";

import { businessProps } from "../../../../../Pages/Dashboard/BusinessAdmin/BusinessList";

import { BCustomInput } from "../../../../common/B2BCustomInput";
import { BImageUploader } from "../../../../common/B2BImageUploader";
import { BRadio } from "../../../../common/B2BRadio";
import { BCheckBox } from "../../../../common/BCheckBox";
import {
  businessFormDataProps,
  businessServicesOptions,
  businessStatusRadioData,
  businessTypeRadioData
} from "../../../../../Pages/Dashboard/BusinessAdmin/AddBusiness";
import "./index.css";

interface editBusinessProps {
  data?: businessProps;
  className?: string;
  formData: businessFormDataProps;
  setFormData: React.Dispatch<React.SetStateAction<businessFormDataProps>>;
}

export const EditBusiness: React.FC<editBusinessProps> = ({
  className,
  formData,
  setFormData
}) => {
  return (
    <div className={`${className} w-full`}>
      <div className="grid grid-flow-row grid-cols-12 gap-3">
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Logo</span>
        </div>
        <div className="col-span-7">
          <BImageUploader
            formData={formData}
            name="businessLogo"
            setFormData={setFormData}
            id="businessLogo"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business ID</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            formData={formData}
            setFormData={setFormData}
            type="text"
            name="businessID"
            id="businessID"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Name</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            formData={formData}
            setFormData={setFormData}
            type="text"
            name="businessName"
            id="businessName"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Email</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            setFormData={setFormData}
            formData={formData}
            type="text"
            name="businessEmail"
            id="businessEmail"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Phone</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            formData={formData}
            setFormData={setFormData}
            type="text"
            name="businessPhone"
            id="businessPhone"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Address</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            formData={formData}
            setFormData={setFormData}
            type="text"
            name="businessAddress"
            id="businessAddress"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business City</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            setFormData={setFormData}
            formData={formData}
            type="text"
            name="businessCity"
            id="businessCity"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Country</span>
        </div>
        <div className="col-span-7">
          <BCustomInput
            setFormData={setFormData}
            formData={formData}
            type="text"
            name="businessCountry"
            id="businessCountry"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Services</span>
        </div>
        <div className="col-span-7">
          <BCheckBox
            formData={formData}
            options={businessServicesOptions}
            setFormData={setFormData}
            name="businessService"
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Status</span>
        </div>
        <div className="col-span-7">
          <BRadio
            setFormData={setFormData}
            inline={true}
            name="busiessStatus"
            id="businessStatus"
            radios={businessStatusRadioData}
            formData={formData}
          />
        </div>
        <div className="col-span-5">
          <span className="text-sm text-secondary">Business Type</span>
        </div>
        <div className="col-span-7">
          <BRadio
            name="businessType"
            formData={formData}
            id="businessType"
            setFormData={setFormData}
            inline={true}
            radios={businessTypeRadioData}
          />
        </div>
      </div>
    </div>
  );
};
