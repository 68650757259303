import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../../Components/common/B2BButton";
import { BCustomInput } from "../../../../Components/common/B2BCustomInput";
import { BSelect } from "../../../../Components/common/BSelect";
import { b2bConfig } from "../../../../Config";
import { Instance } from "../../../../Services/instance";
import { RootState } from "../../../../Store";
import { bzChildValueProps } from "../../../../Store/Reducers/common";
import "./index.css";

interface exchangeEntryPageProps {
  className?: string;
}
interface exEntryFormProps {
  "currfrom": string;
  "currto": string;
  "buy": string;
  "sell": string;
  "source": string;
  "remark": string;
}

export const ExchangeEntryPage: React.FC<exchangeEntryPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [exchangeEntryForm, setExchangeEntryForm] = useState<exEntryFormProps>({
    currfrom: "",
    currto: "MMK",
    buy: "",
    sell: "",
    source: "",
    remark: ""
  });
  const { addToast } = useToasts();
  const SubmitEntry = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/admin-exchange-entry/`,
          method: "POST",
          data: exchangeEntryForm
        })
          .then(res => {
            if (res.data && res.data.Status.Code === "01") {
              addToast(`Success`, { appearance: "success", autoDismiss: true });
            } else if (res.data && res.data.Status.Code === "500") {
              addToast(`${JSON.stringify(res.data.Error)}`, {
                appearance: "warning",
                autoDismiss: true
              });
            }
          })
          .catch(err => {
            addToast(`${JSON.stringify(err)}`, {
              appearance: "error",
              autoDismiss: true
            });
          })
          .finally(() => {
            setExchangeEntryForm({
              currfrom: "",
              currto: "MMK",
              buy: "",
              sell: "",
              source: "",
              remark: ""
            });
          });
      }
    } catch (error) {
      addToast(`System error - ${JSON.stringify(error)}`, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  const selectOptions: bzChildValueProps[] = [
    {
      name: "Please select",
      value: ""
    },
    {
      name: "USD",
      value: "USD"
    },
    {
      name: "USG",
      value: "USG"
    },
    {
      name: "MMK",
      value: "MMK"
    }
  ];
  return (
    <div className={`${className} w-full`}>
      <form
        onSubmit={SubmitEntry}
        className="px-4 py-4 shadow-md w-full max-w-screen-md flex flex-col space-y-2 select-none"
      >
        <BSelect
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="currfrom"
          id="currfrom"
          label="Currency From"
          options={selectOptions.filter(v => v.value !== "MMK")}
        />
        <BSelect
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="currto"
          id="currto"
          label="Currency To"
          options={selectOptions.filter(value => value.value === "MMK")}
        />

        <BCustomInput
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="buy"
          id="buy"
          required={true}
          label="Buy"
          type="text"
        />
        <BCustomInput
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="sell"
          id="sell"
          required={true}
          label="Sell"
          type="text"
        />
        <BCustomInput
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="source"
          id="source"
          required={true}
          label="Source"
          type="text"
        />
        <BCustomInput
          formData={exchangeEntryForm}
          setFormData={setExchangeEntryForm}
          name="remark"
          id="remark"
          required={true}
          label="Remark"
          type="text"
        />
        <div className="flex justify-start">
          <Bbutton type="submit" title="Submit" />
        </div>
      </form>
    </div>
  );
};
