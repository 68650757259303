import React from "react";
import {BrowserRouter as Router,Switch,Redirect,Route} from 'react-router-dom'
import { UnauthenticatedPage } from "../Pages/401";
import { NotFoundPage } from "../Pages/404";
import { AuthPage } from "../Pages/Auth/Login";
import { DashboardHome } from "../Pages/Dashboard/Home";
export const RootRouter:React.FC=()=>{
    return(
        <Router>
            <Switch>
                <Route path='/' exact component={AuthPage} />
                <Route path='/dashboard' component={DashboardHome} />
                <Route path='/404' exact component={NotFoundPage} />
                <Route path='/401' exact component={UnauthenticatedPage} />
                <Redirect to='/404' />
            </Switch>
        </Router>
    )
}