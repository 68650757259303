import React from "react";

interface selectTableProps {
  className?: string;
}
export const SelectTable: React.FC<selectTableProps> = ({
  className,
  children
}) => {
  return (
    <div className={`${className} overflow-x-auto`}>
      <table className="w-full table-auto">
        {children}
      </table>
    </div>
  );
};

export const TableRow: React.FC<selectTableProps> = ({
  className,
  children
}) => {
  return (
    <tr className={`${className}`}>
      {children}
    </tr>
  );
};

interface tableCellProps {
  isHeader?: boolean;
  className?: string;
}

export const TableCell: React.FC<tableCellProps> = ({
  className,
  isHeader = false,
  children
}) => {
  if (isHeader) {
    return (
      <th
        className={`px-2 py-3  text-left first:pl-5 last:pr-5 whitespace-nowrap ${className}`}
      >
        {children}
      </th>
    );
  } else {
    return (
      <td
        className={`px-2 first:pl-5 last:pr-5 py-3  whitespace-nowrap ${className}`}
      >
        {children}
      </td>
    );
  }
};
