import React from "react";
import "./index.css";
export interface radioDataProps {
    value: string,
    text: string
}
interface bRadioProps {
    className?: string;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    inline?: boolean;
    name: string;
    id: string;
    radios?: radioDataProps[]
    formData:any
}

export const BRadio: React.FC<bRadioProps> = ({
    className,
   setFormData,
    inline = false,
    name,
    id,
    radios,
    formData
}) => {
    console.log(formData,['radio'])
    return (
        <div className={`${className} ${inline ? 'flex flex-row space-x-2' : 'flex flex-col space-y-2'}`}>
            {
                radios?.map((r: radioDataProps, i: number) => (
                    <div key={i} className={`flex space-x-1 justify-center items-center content-center`}>
                        <input
                            onChange={e => {
                                setFormData({...formData,[e.target.name]:e.target.value});
                            }}
                            type="radio"
                            value={r.value}
                            name={name}
                            id={id}
                            checked={formData[name]===r.value}
                            defaultChecked={i===0}
                        />
                        <span className='text-sm text-secondary'>{r.text} </span>
                    </div>
                ))
            }

        </div>
    );
};
