import React from "react";
import "./index.css"

interface authWrapperProps {
    className?: string,
    title?: string
}

export const AuthWrapper: React.FC<authWrapperProps> = ({ title = "b2badmin", className, children }) => {
    document.title = title
   

    return (
        <div className={`${className} flex w-full justify-center min-h-screen items-center content-center bg-gray-200`} >
            <div className='w-full max-w-md px-2 py-2'>
                {children}
            </div>
           
        </div>
    )
}