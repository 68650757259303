import React from 'react'
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from '../../../Templates/DashbaordInnerWrapper'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { RentalListPage } from './RentalList'
import { RentalBookingsPage } from './RentalBookings'
import { AddNewRentalPage } from './AddNewRental'
export const CarRentalAdminPage: React.FC = () => {
  const { path } = useRouteMatch()
  const menus: tabMenuProps[] = [
    {
      name: 'Rental List',
      path: `${path}`
    },
    {
      name: 'Rental Bookings',
      path: `${path}/rental-bookings`
    },
    {
      name: 'Add New Rental',
      path: `${path}/add-new-rental`
    }
  ]
  return (
    <DashbaordInnerWrapper tabMenus={menus}>
      <Switch>
        <Route path={`${path}`} exact component={RentalListPage} />
        <Route
          path={`${path}/rental-bookings`}
          exact
          component={RentalBookingsPage}
        />
        <Route
          path={`${path}/add-new-rental`}
          exact
          component={AddNewRentalPage}
        />
      </Switch>
    </DashbaordInnerWrapper>
  )
}
