
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { b2bConfig } from '../../../../Config'
import { Instance } from '../../../../Services/instance'
import { RootState } from '../../../../Store'
import format from 'date-fns/format'
import './index.css'
import { BPopup } from '../../../../Components/common/B2BPopup'
import { BRadio, radioDataProps } from '../../../../Components/common/B2BRadio'
import { BCustomInput } from '../../../../Components/common/B2BCustomInput'
import { BImageUploader } from '../../../../Components/common/B2BImageUploader'
import { Bbutton } from '../../../../Components/common/B2BButton'
import { useToasts } from 'react-toast-notifications'
import {
  SelectTable,
  TableCell,
  TableRow
} from '../../../../Components/common/SelectTable'

interface announceLstPageProps {
  className?: string
}
export interface announcementsProps {
  attached?: string
  id?: number
  message?: string
  postdate?: string
  title?: string
}
export const DocumentRender = (props: any) => {
  return props && props.data && props.data.attached ? (
    <>
      <a
        target='_blank'
        rel='noreferrer'
        className=' flex flex-row space-x-2 px-2 py-1 rounded-md text-xs font-normal text-secondary appearance-none ring-0 focus:outline-none border border-success'
        href={props.data.attached}
      >
        {' '}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-paperclip'
          viewBox='0 0 16 16'
        >
          <path d='M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z' />
        </svg>{' '}
        attached file
      </a>
    </>
  ) : (
    <></>
  )
}


export interface annoucementFormProps {
  annType: 'AGENT' | 'SUPPLIER'
  title: string
  Message: string
  attachment: string
  annID: string | number
}

const radioOptions: radioDataProps[] = [
  {
    text: 'Agent',
    value: 'AGENT'
  },
  {
    text: 'Supplier',
    value: 'SUPPLIER'
  }
]
export const AnnouncementLstPage: React.FC<announceLstPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth)
  const [announcements, setAnnouncements] = useState<announcementsProps[]>([])

  const [show, setShow] = useState<boolean>(false)
  const { addToast } = useToasts()
  const [annoucementForm, setAnnoucementForm] = useState<annoucementFormProps>({
    annType: 'AGENT',
    title: '',
    Message: '',
    attachment: '',
    annID: ''
  })
  const UpdateAnnouncement = (data: announcementsProps) => {
    setShow(!show)
    setAnnoucementForm({
      annType: 'AGENT',
      title: data.title ? data.title : '',
      Message: data.message ? data.message : '',
      attachment: data.attached ? data.attached : '',
      annID: data.id ? data.id : ''
    })
  }
  useEffect(() => {
    if (isAuth) {
      InitApi()
    }
  }, [isAuth])

  const InitApi = () => {
    Instance({
      url: `${b2bConfig.apiPath.bzEntry}/announcement`,
      method: 'GET'
    })
      .then(res => {
        if (res.status === 200) {
          if (res.data && res.data.Data) {
            const response: announcementsProps[] = res.data.Data
            setAnnouncements(response)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  const SubmitAnnouncementForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      let formObj: any = {}
      if (annoucementForm.attachment.startsWith('data:')) {
        formObj['attachment'] = annoucementForm.attachment
      }
      formObj['annID'] = annoucementForm.annID
      formObj['title'] = annoucementForm.title
      formObj['annType'] = annoucementForm.annType
      formObj['Message'] = annoucementForm.Message
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/admin-update-announcement/`,
        method: 'POST',
        data: formObj
      })
        .then(res => {
          if (res.data && res.data.Status.Code === '01') {
            addToast(`Success`, { appearance: 'success', autoDismiss: true })
          } else if (res.data && res.data.Status.Code === '500') {
            addToast(`${JSON.stringify(res.data.Error)}`, {
              appearance: 'warning',
              autoDismiss: true
            })
          }
        })
        .catch(err => {
          addToast(`${JSON.stringify(err)}`, {
            appearance: 'error',
            autoDismiss: true
          })
        })
        .finally(() => {
          setShow(!show)
          InitApi()
        })
    } catch (error) {}
  }
  return (
    <div className={`${className}`}>
      <SelectTable className='text-gray-800'>
        <thead className='text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200'>
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Title</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Message</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Attached </span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-three-dots'
                viewBox='0 0 16 16'
              >
                <path d='M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className='text-xs font-normal divide-y divide-gray-400 bg-white'>
          {announcements.map((a, i) => (
            <TableRow key={i}>
              <TableCell>
                <span>{i + 1} </span>
              </TableCell>
              <TableCell>
                <span>{a.id} </span>
              </TableCell>
              <TableCell>
                <span>{a.title} </span>
              </TableCell>
              <TableCell className='whitespace-pre-wrap'>
                <span>{a.message} </span>
              </TableCell>
              <TableCell>
                <span>
                  {a.postdate && format(new Date(a.postdate), 'yyyy-MM-dd')}{' '}
                </span>
              </TableCell>
              <TableCell className=''>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className=' flex flex-row space-x-2 px-2 py-1 rounded-md text-xs font-normal text-secondary appearance-none ring-0 focus:outline-none '
                  href={a.attached}
                >
                  download
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-paperclip'
                    viewBox='0 0 16 16'
                  >
                    <path d='M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z' />
                  </svg>
                </a>
              </TableCell>
              <TableCell>
                <button
                  onClick={() => {
                     UpdateAnnouncement(a)
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </SelectTable>
      
      <BPopup show={show} setShow={setShow} title='Announcement '>
        <form
          onSubmit={e => {
            SubmitAnnouncementForm(e)
          }}
          className='w-full flex flex-col max-w-screen-md px-4 py-4 shadow-md'
        >
          <div className='flex flex-col space-y-2'>
            <label className=' text-sm text-secondary' htmlFor=''>
              Select Type
            </label>
            <BRadio
              inline
              setFormData={setAnnoucementForm}
              formData={annoucementForm}
              radios={radioOptions}
              name='annType'
              id='annType'
            />
          </div>
          <BCustomInput
            label='Title'
            formData={annoucementForm}
            setFormData={setAnnoucementForm}
            name='title'
            id='title'
            type='text'
            required={true}
          />
          <BCustomInput
            label='Message'
            formData={annoucementForm}
            setFormData={setAnnoucementForm}
            name='Message'
            id='Message'
            type='text'
            required={true}
          />
          <BImageUploader
            formData={annoucementForm}
            setFormData={setAnnoucementForm}
            label='Attachment'
            name='attachment'
            id='attachment'
          />
          <div className='flex justify-start py-2'>
            <Bbutton title='Submit' type='submit' />
          </div>
        </form>
      </BPopup>
    </div>
  )
}
