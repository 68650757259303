import React from "react";
import "./index.css";
import Select from "react-select";
export interface b2bAutoCompleteOptions {
  name: string;
  value: any;
}
interface bAutoCompleteProps {
  className?: string;
  items?: b2bAutoCompleteOptions[];
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  title?: string;
  name: string;
  id: string;
  formData: any;
}

export const BAutoComplete: React.FC<bAutoCompleteProps> = ({
  className,
  items,
  setFormData,
  title,
  name,
  id,
  formData
}) => {
  return (
    <div className={`${className} b2b_autocomplete w-full `}>
      {title &&
        <label className="text-secondary text-sm" htmlFor={name}>
          {title}
        </label>}
      <Select
        name={name}
        id={id}
        options={items}
        isSearchable={true}
        isClearable={true}
        getOptionLabel={option => option.name}
        onChange={item => {
          setFormData({ ...formData, [name]: item });
        }}
      />
    </div>
  );
};
