import React from "react";
import './index.css'

interface legerPageProps{
    className?:string
}

export const LegerPage:React.FC<legerPageProps> = ({className})=>{
    return(
        <div className={`${className}`}>
            <h3>Leger  Page </h3>
        </div>
    )
}